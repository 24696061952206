import useSnackbar from 'hooks/useSnackbar';
import { useMutation } from '@tanstack/react-query';
import { validationRejection } from 'services/reactQueryservices';
const useValidationRejection = () => {
    const snackbar = useSnackbar();
    const { mutate, error, isSuccess, isError, isLoading } = useMutation({
        mutationFn: validationRejection,
        onSettled: (data, error) => {
            if (error) {
                snackbar('Something went wrong', 'error');
            } else {
                snackbar('Operation Successful');
            }
        }
    });
    return {
        mutate,
        error,
        isSuccess,
        isError,
        isLoading
    };
}

export default useValidationRejection;