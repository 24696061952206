// material-ui
import { Typography } from '@mui/material';
// import useAuth from 'hooks/useAuth';
import getMenuItems from 'menu-items';
// project imports
import NavGroup from './NavGroup';

import { useEffect, useState } from 'react';
import WebAssetRounded from '@mui/icons-material/WebAssetRounded';
import WorkspacesTwoTone from '@mui/icons-material/WorkspacesTwoTone';
import AdbRounded from '@mui/icons-material/AdbRounded';
import GTranslateTwoTone from '@mui/icons-material/GTranslateTwoTone';
import Kayaking from '@mui/icons-material/Kayaking';

// ==============================|| SIDEBAR MENU LIST ||============================== //

const dashboardIcons: any = {
    0: WebAssetRounded,
    1: WorkspacesTwoTone,
    2: AdbRounded,
    3: GTranslateTwoTone,
    4: Kayaking
};

const menuItemsFormatter = (existingMenu: any, dataList: any[]) => {
    console.log(dashboardIcons[0]);

    const formattedList: any[] = [];

    // to check if the id exist but is removed so that i can remove it from the existing list
    const updatedDashboardItemsIdList: any[] = [];
    dataList.forEach((item, index) => {
        updatedDashboardItemsIdList.push(`dynamicDashboard_${item.id}`);
    });
    if (existingMenu.items[0].children) {
        const toRemoveIndexesList: any[] = [];
        existingMenu.items[0].children.forEach((item: any, index: number) => {
            if (item.id.startsWith('dynamicDashboard_') && !updatedDashboardItemsIdList.includes(item.id)) {
                toRemoveIndexesList.push(index);
            }
        });
        toRemoveIndexesList.forEach((toRemoveindex: number, iterNumber: number) => {
            existingMenu.items[0].children.splice(toRemoveindex, 1);
        });
    }
    // ------------------ updated existingMenu --------------------------

    dataList.forEach((item, index) => {
        if (existingMenu.items[0].children) {
            console.log('item.id ==>> ', item.id);

            const objectExists = existingMenu.items[0].children.some((obj: any) => obj.id === `dynamicDashboard_${item.id}`);
            if (!objectExists) {
                formattedList.push({
                    id: `dynamicDashboard_${item.id}`,
                    title: item.name,
                    type: 'item',
                    url: `/dashboard/${item.id}`,
                    icon: dashboardIcons[index],
                    breadcrumbs: false
                });
            }
        }
    });
    return formattedList;
};

const updateSidebarList = (mainMenu: any, sidebarData: any[]) => {
    const formattedList = menuItemsFormatter(mainMenu, sidebarData);

    if (mainMenu.items[0].children) {
        mainMenu.items[0].children = [...mainMenu.items[0].children, ...formattedList];
    }
    return mainMenu;
};

const MenuList = ({ sidebarData = [] }: any) => {
    const [menuItems, setMenuItems] = useState(getMenuItems(false));

    // useEffect(() => {
    //     setMenuItems(updateSidebarList(getMenuItems(false), sidebarData));
    // }, [sidebarData]);

    console.log(menuItems, "--------menu items----------")

    const navItems = menuItems.items.map((item: any) => {
        switch (item.type) {
            case 'group':
                return <NavGroup key={item.id} item={item} />;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    return <>{navItems}</>;
};

export default MenuList;
