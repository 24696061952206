import { useQuery } from '@tanstack/react-query';
import { getMapData } from 'services/reactQueryservices';
import { mapDataKey } from 'react_query/query-keys';

const useMapdata = (locationType: string) => {
    const { data, isFetching, error } = useQuery({
        queryKey: [mapDataKey, locationType],
        queryFn: () => getMapData(locationType)
    });

    return {
        data,
        isFetching,
        error
    };
};

export default useMapdata;
