import { useQuery } from '@tanstack/react-query';
import { getInfrastructureChartData } from 'services/reactQueryservices';
import { infrastructureKey } from 'react_query/query-keys';

const useInfrastructureChart = () => {
    const { data, isFetching, error } = useQuery({
        queryKey: [infrastructureKey],
        queryFn: () => getInfrastructureChartData()
    });

    return {
        data,
        isFetching,
        error
    };
}

export default useInfrastructureChart