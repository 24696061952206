import useDemonstrationlist from 'hooks/consume_api/query/useDemonstrationlist'
import React, { useState } from 'react'
import Loader from 'ui-component/Loader';
import {
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Stack,
    Grid,
    Pagination
} from '@mui/material';

interface IDemonstrationList {
    b_first_name: string,
    b_middle_name: string,
    b_last_name: string,
    fathersname: string,
    member_id: string,
    union_name: string,
    upazila: string,
    district: string,
    demo: string
}

const DemonstrationMain = () => {
    const [page, setPage] = useState<number>(1);
    const { data, isFetching } = useDemonstrationlist(page);

    const handleChangePage = (_: React.ChangeEvent<unknown>, newPage: number) => {
        setPage(newPage);
    };
    return (
        <>
            {isFetching && <Loader />}
            <TableContainer component={Paper}>
                <Table sx={{ width: '100%' }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">#</TableCell>
                            <TableCell align="left">Name</TableCell>
                            <TableCell align="left">Father's name</TableCell>
                            <TableCell align="left">Beneficiary Id</TableCell>
                            <TableCell align="left">Union</TableCell>
                            <TableCell align="left">Upazila</TableCell>
                            <TableCell align="left">District</TableCell>
                            <TableCell align="left">Demonstration name</TableCell>
                            <TableCell align="center">Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data?.data?.data && data.data.data.map((item: IDemonstrationList, index: number) => (
                            <TableRow key={index}>
                                <TableCell align="left">{(page - 1) * 10 + index + 1}</TableCell>
                                <TableCell align="left">{item.b_first_name} {item.b_middle_name} {item.b_last_name}</TableCell>
                                <TableCell align="left">{item.fathersname}</TableCell>
                                <TableCell align="left">{item.member_id}</TableCell>
                                <TableCell align="left">{item.union_name}</TableCell>
                                <TableCell align="left">{item.upazila}</TableCell>
                                <TableCell align="left">{item.district}</TableCell>
                                <TableCell align="left">{item.demo}</TableCell>
                                <TableCell align="left">
                                    <Stack direction="row">
                                        <Button>Add Gps</Button>
                                        <Button>Add Production</Button>
                                    </Stack>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Grid display="flex" justifyContent="end" marginTop="10px">
                <Pagination count={Math.ceil(data?.data.total / 10)} color="primary" page={page} onChange={handleChangePage} />
            </Grid>
        </>
    )
}

export default DemonstrationMain