import React, { useEffect, useState } from 'react';
import { Autocomplete, CardContent, Grid, TextField, Typography } from '@mui/material';
import MainCard from '../../../../../ui-component/cards/MainCard';
import UserCountCard from '../../../../../ui-component/cards/UserCountCard';
import GroupsTwoToneIcon from '@mui/icons-material/GroupsTwoTone';
import GroupTwoToneIcon from '@mui/icons-material/GroupTwoTone';
import MaleTwoToneIcon from '@mui/icons-material/MaleTwoTone';
import FemaleTwoToneIcon from '@mui/icons-material/FemaleTwoTone';
import { useTheme } from '@mui/material/styles';
import { gridSpacing } from '../../../../../store/constant';
import TotalGrowthBarChart from './TotalGrowthBarChart';
import useGetCardData from '../../../../../hooks/consume_api/query/useGetCardData';
import useGetChartData from '../../../../../hooks/consume_api/query/useGetChartData';
import useGetLocationList from 'hooks/consume_api/query/useGetLocationList';
import { project, agency } from './utils/dropDownData';
import Map from './Map';
import useDashboardBenificiary from 'hooks/consume_api/query/useDashboardBenificiary';
import Loader from 'ui-component/Loader';
import useDashboardTraining from 'hooks/consume_api/query/useDashboardTraining';
import useDashboardDemonstration from 'hooks/consume_api/query/useDashboardDemonstration';
import useDashboardInput from 'hooks/consume_api/query/useDashboardInput';
import useDashboardInfrastructure from 'hooks/consume_api/query/useDashboardInfrastructure';
import Benificiary from './Benificiary';
import Demonstration from './Demonstration';
import ProjectActivity from './ProjectActivity';
import Infrastructure from './Infrastructure';

const Index = () => {
    const theme = useTheme();
    const { cardData, isCardDataFetched, isCardDataFetching } = useGetCardData();
    const [chartOption, setChartOption] = useState('member');
    const { chartData, isChartDataFetching, isChartDataFetched } = useGetChartData(chartOption);
    const [keys, setKeys] = useState<string[]>([]);
    const [values, setValues] = useState<number[]>([]);
    const [location, setLocation] = useState<string>('-1');
    const [divisionId, setDivisionId] = useState<string>('-1');
    const [districtId, setDistrictId] = useState<string>('-1');
    const [upazilaId, setUpazilaId] = useState<string>('-1');
    const [unionId, setUnionId] = useState<string>('-1');
    const [projectId, setProjectId] = useState<string>('-1');
    const [agencyId, setAgencyId] = useState<string>('-1');
    const [yearData, setYearData] = useState<string>('-1');

    const { data: divisionData } = useGetLocationList(location);
    const { data: distirctData } = useGetLocationList(divisionId);
    const { data: upazilaData } = useGetLocationList(districtId);
    const { data: unionData } = useGetLocationList(upazilaId);

    const { data: beneficiaryData, isFetched: beneficiaryIsFetched } = useDashboardBenificiary(
        divisionId,
        districtId,
        upazilaId,
        unionId,
        yearData,
        projectId
    );
    const { data: trainingData, isFetched: trainingIsFetched } = useDashboardTraining(
        divisionId,
        districtId,
        upazilaId,
        unionId,
        yearData,
        projectId,
        agencyId
    )
    const { data: demonstrationData, isFetched: demonstrationIsFetched } = useDashboardDemonstration(
        projectId,
        agencyId,
        divisionId,
        districtId,
        upazilaId,
        yearData
    )

    const { data: inputData, isFetched: inputIsFetched } = useDashboardInput(
        projectId,
        agencyId,
        divisionId,
        districtId,
        upazilaId,
        yearData
    )

    const { data: infrastructureData, isFetched: infrastructureIsFetch } = useDashboardInfrastructure(projectId, agencyId, divisionId, districtId, upazilaId);

    const year = [];

    for (let i = 2015; i <= 2030; i++) {
        year.push({ label: `${i}`, value: `${i}` });
    }

    useEffect(() => {
        if (!isChartDataFetching && isChartDataFetched && chartData.length > 0) {
            setKeys(chartData.map((data) => data.key ?? 'N/A'));
            setValues(chartData.map((data) => parseInt(data.value)));
        }
    }, [chartData]);

    interface FieldOption {
        id: number;
        field_name: string;
        field_parent_id: number;
        field_type_id: number;
        geocode: string;
        geojson: object;
        uploaded_file_path: string;
        created_by: number;
        updated_by: number;
        created_date: string;
        updated_date: string;
        longitude: number | null;
        latitude: number | null;
        field_name_bn: string | null;
    }

    return (
        <MainCard content={false} contentSX={{ padding: 0 }} title={''}>
            {beneficiaryIsFetched === false && <Loader />}
            {trainingIsFetched === false && <Loader />}
            {demonstrationIsFetched === false && <Loader />}
            {inputIsFetched === false && <Loader />}
            {infrastructureIsFetch === false && <Loader />}
            <CardContent>
                <Grid container spacing={gridSpacing} marginBottom="15px">
                    <Grid item lg={3} sm={6}>
                        <Autocomplete
                            options={project}
                            getOptionLabel={(option) => option.label}
                            onChange={(event, value) => {
                                if (value) setProjectId(value.id);
                            }}
                            renderInput={(params) => <TextField {...params} label="Select project" variant="outlined" />}
                        />
                    </Grid>
                    <Grid item lg={3} sm={6}>
                        <Autocomplete
                            options={agency}
                            getOptionLabel={(option) => option.label}
                            onChange={(event, value) => {
                                if (value) setAgencyId(value.id);
                            }}
                            renderInput={(params) => <TextField {...params} label="Select agency" variant="outlined" />}
                        />
                    </Grid>
                    <Grid item lg={3} sm={6}>
                        <Autocomplete
                            options={divisionData?.data as FieldOption[]}
                            getOptionLabel={(option) => option.field_name}
                            value={divisionData?.data.find((item: any) => item.id.toString() === divisionId) || null}
                            onChange={(event, value) => {
                                if (value) {
                                    setDivisionId(value?.id.toString());
                                    setDistrictId('-1');
                                    setUpazilaId('-1');
                                    setUnionId('-1');
                                }
                            }}
                            renderInput={(params) => <TextField {...params} label="Select division" variant="outlined" />}
                        />
                    </Grid>
                    <Grid item lg={3} sm={6}>
                        <Autocomplete
                            options={(distirctData?.data as FieldOption[]) ?? []}
                            getOptionLabel={(option) => option.field_name}
                            value={distirctData?.data.find((item: any) => item.id.toString() === districtId) || null}
                            onChange={(event, value) => {
                                if (value) {
                                    setDistrictId(value?.id.toString());
                                    setUpazilaId('-1');
                                    setUnionId('-1');
                                }
                            }}
                            renderInput={(params) => <TextField {...params} label="Select district" variant="outlined" />}
                        />
                    </Grid>
                    <Grid item lg={3} sm={6}>
                        <Autocomplete
                            options={(upazilaData?.data as FieldOption[]) ?? []}
                            getOptionLabel={(option) => option.field_name}
                            value={upazilaData?.data.find((item: any) => item.id.toString() === upazilaId) || null}
                            onChange={(event, value) => {
                                if (value) {
                                    setUpazilaId(value?.id.toString());
                                    setUnionId('-1');
                                }
                            }}
                            renderInput={(params) => <TextField {...params} label="Select upazila" variant="outlined" />}
                        />
                    </Grid>
                    <Grid item lg={3} sm={6}>
                        <Autocomplete
                            options={(unionData?.data as FieldOption[]) ?? []}
                            getOptionLabel={(option) => option.field_name}
                            value={unionData?.data.find((item: any) => item.id.toString() === unionId) || null}
                            onChange={(event, value) => {
                                if (value) setUnionId(value?.id.toString());
                            }}
                            renderInput={(params) => <TextField {...params} label="Select union" variant="outlined" />}
                        />
                    </Grid>
                    <Grid item lg={3} sm={6}>
                        <Autocomplete
                            options={year}
                            getOptionLabel={(option) => option.label}
                            // value={year.find((item: any) => item.id.toString() === unionId) || null}
                            onChange={(event, value) => {
                                if (value) setYearData(value.value);
                            }}
                            renderInput={(params) => <TextField {...params} label="Select year" variant="outlined" />}
                        />
                    </Grid>
                </Grid>
                <Typography variant="h4" marginBottom="10px">
                    Beneficiary
                </Typography>
                <Grid container spacing={gridSpacing}>
                    <Grid item xs={12} lg={3} sm={6}>
                        <UserCountCard
                            primary="Total beneficiaries"
                            secondary={beneficiaryData?.data.total_beneficiary}
                            iconPrimary={GroupTwoToneIcon}
                            color={theme.palette.primary.dark}
                        />
                    </Grid>
                    <Grid item xs={12} lg={3} sm={6}>
                        <UserCountCard
                            primary="Total Male beneficiaries"
                            secondary={beneficiaryData?.data.total_male_beneficiary}
                            iconPrimary={GroupTwoToneIcon}
                            color={theme.palette.primary.dark}
                        />
                    </Grid>
                    <Grid item xs={12} lg={3} sm={6}>
                        <UserCountCard
                            primary="Total Female beneficiaries"
                            secondary={beneficiaryData?.data.total_female_beneficiary}
                            iconPrimary={GroupTwoToneIcon}
                            color={theme.palette.primary.dark}
                        />
                    </Grid>
                    {/* <Grid item xs={12} lg={3} sm={6}>
                        <UserCountCard
                            primary="Total Female Households"
                            secondary={beneficiaryData?.data.total_female_house_hold}
                            iconPrimary={GroupTwoToneIcon}
                            color={theme.palette.primary.dark}
                        />
                    </Grid> */}
                    <Grid item xs={12} lg={3} sm={6}>
                        <UserCountCard
                            primary="Total Female Households"
                            secondary={beneficiaryData?.data.total_female_house_hold}
                            iconPrimary={GroupTwoToneIcon}
                            color={theme.palette.primary.dark}
                        />
                    </Grid>
                    <Grid item xs={12} lg={3} sm={6}>
                        <UserCountCard
                            primary="Total Youths"
                            secondary={beneficiaryData?.data.total_youth}
                            iconPrimary={GroupTwoToneIcon}
                            color={theme.palette.primary.dark}
                        />
                    </Grid>
                    <Grid item xs={12} lg={3} sm={6}>
                        <UserCountCard
                            primary="Total Male Youths"
                            secondary={beneficiaryData?.data.total_male_youth}
                            iconPrimary={GroupTwoToneIcon}
                            color={theme.palette.primary.dark}
                        />
                    </Grid>
                    <Grid item xs={12} lg={3} sm={6}>
                        <UserCountCard
                            primary="Total female Youths"
                            secondary={beneficiaryData?.data.total_female_youth}
                            iconPrimary={GroupTwoToneIcon}
                            color={theme.palette.primary.dark}
                        />
                    </Grid>
                    <Grid item xs={12} lg={3} sm={6}>
                        <UserCountCard
                            primary="Total Ethnic Households"
                            secondary={beneficiaryData?.data.ethnicCount}
                            iconPrimary={GroupTwoToneIcon}
                            color={theme.palette.primary.dark}
                        />
                    </Grid>
                    {/* <Grid item xs={12} lg={3} sm={6}>
                        <UserCountCard
                            primary="Total Member"
                            secondary={isCardDataFetched && !isCardDataFetching ? cardData?.totalMember ?? 'N/A' : '...'}
                            iconPrimary={MaleTwoToneIcon}
                            color={theme.palette.success.dark}
                        />
                    </Grid>
                    <Grid item xs={12} lg={3} sm={6}>
                        <UserCountCard
                            primary="Male"
                            secondary={isCardDataFetched && !isCardDataFetching ? cardData?.male ?? 'N/A' : '...'}
                            iconPrimary={MaleTwoToneIcon}
                            color={theme.palette.success.dark}
                        />
                    </Grid>
                    <Grid item xs={12} lg={3} sm={6}>
                        <UserCountCard
                            primary="Female"
                            secondary={isCardDataFetched && !isCardDataFetching ? cardData?.female ?? 'N/A' : '...'}
                            iconPrimary={FemaleTwoToneIcon}
                            color={theme.palette.error.light}
                        />
                    </Grid>
                    <Grid item xs={12} lg={3}>
                        <UserCountCard
                            primary="Total Group Registered"
                            secondary={isCardDataFetched && !isCardDataFetching ? cardData?.totalGroup ?? 'N/A' : '...'}
                            iconPrimary={GroupsTwoToneIcon}
                            color={theme.palette.warning.dark}
                        />
                    </Grid>
                    <Grid item xs={12} lg={3}>
                        <UserCountCard
                            primary="Total Event"
                            secondary={isCardDataFetched && !isCardDataFetching ? cardData?.totalEvent ?? 'N/A' : '...'}
                            iconPrimary={GroupsTwoToneIcon}
                            color={theme.palette.info.light}
                        />
                    </Grid>
                    <Grid item xs={12} lg={3}>
                        <UserCountCard
                            primary="Total Input"
                            secondary={isCardDataFetched && !isCardDataFetching ? cardData?.totalInput ?? 'N/A' : '...'}
                            iconPrimary={GroupsTwoToneIcon}
                            color={theme.palette.secondary.dark}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TotalGrowthBarChart
                            keys={keys}
                            values={values}
                            chartOption={chartOption}
                            setChartOption={setChartOption}
                            isLoading={false}
                        />
                    </Grid> */}
                </Grid>
                <Typography variant="h4" marginBottom="10px" marginTop="10px">
                    Training/Events
                </Typography>
                <Grid container spacing={gridSpacing}>
                    <Grid item xs={12} lg={3} sm={6}>
                        <UserCountCard
                            primary="Completed Training"
                            secondary={trainingData?.data.completed_training}
                            iconPrimary={GroupsTwoToneIcon}
                            color={theme.palette.success.dark}
                        />
                    </Grid>
                    <Grid item xs={12} lg={3} sm={6}>
                        <UserCountCard
                            primary="Total beneficiaries received training"
                            secondary={trainingData?.data.totalBeneficiariesRecievedTraining}
                            iconPrimary={GroupsTwoToneIcon}
                            color={theme.palette.success.dark}
                        />
                    </Grid>
                    <Grid item xs={12} lg={3} sm={6}>
                        <UserCountCard
                            primary="Unique beneficiaries received training"
                            secondary={trainingData?.data.uniqueBeneficiaries}
                            iconPrimary={GroupsTwoToneIcon}
                            color={theme.palette.success.dark}
                        />
                    </Grid>
                    <Grid item xs={12} lg={3} sm={6}>
                        <UserCountCard
                            primary="Beneficiaries received more then one training"
                            secondary={trainingData?.data.moreThenOneTrain}
                            iconPrimary={GroupsTwoToneIcon}
                            color={theme.palette.success.dark}
                        />
                    </Grid>
                    <Grid item xs={12} lg={3} sm={6}>
                        <UserCountCard
                            primary="Number of male received training"
                            secondary={trainingData?.data.maleBeneficiaries}
                            iconPrimary={GroupsTwoToneIcon}
                            color={theme.palette.success.dark}
                        />
                    </Grid>
                    <Grid item xs={12} lg={3} sm={6}>
                        <UserCountCard
                            primary="Number of female received training"
                            secondary={trainingData?.data.femaleBeneficiaries}
                            iconPrimary={GroupsTwoToneIcon}
                            color={theme.palette.success.dark}
                        />
                    </Grid>
                    <Grid item xs={12} lg={3} sm={6}>
                        <UserCountCard
                            primary="Number of youth received training"
                            secondary={trainingData?.data.youthBeneficiaries}
                            iconPrimary={GroupsTwoToneIcon}
                            color={theme.palette.success.dark}
                        />
                    </Grid>
                    <Grid item xs={12} lg={3} sm={6}>
                        <UserCountCard
                            primary="Number of ethnic received training"
                            secondary={trainingData?.data.ethnicityBeneficiaries}
                            iconPrimary={GroupsTwoToneIcon}
                            color={theme.palette.success.dark}
                        />
                    </Grid>
                </Grid>
                <Typography variant="h4" marginBottom="10px" marginTop="10px">
                    Demonstration
                </Typography>
                <Grid container spacing={gridSpacing}>
                    <Grid item xs={12} lg={3}>
                        <UserCountCard
                            primary="Demonstration completed"
                            secondary={demonstrationData?.data.demonstrationCompleted}
                            iconPrimary={GroupsTwoToneIcon}
                            color={theme.palette.secondary.dark}
                        />
                    </Grid>
                    <Grid item xs={12} lg={3}>
                        <UserCountCard
                            primary="Number of fruits demonstrations"
                            secondary={demonstrationData?.data.demonstrationFruits}
                            iconPrimary={GroupsTwoToneIcon}
                            color={theme.palette.secondary.dark}
                        />
                    </Grid>
                    <Grid item xs={12} lg={3}>
                        <UserCountCard
                            primary="Number of vegetables demonstrations"
                            secondary={demonstrationData?.data.demonstrationVegetables}
                            iconPrimary={GroupsTwoToneIcon}
                            color={theme.palette.secondary.dark}
                        />
                    </Grid>
                    <Grid item xs={12} lg={3}>
                        <UserCountCard
                            primary="Oilseed(other) demonstration"
                            secondary={demonstrationData?.data.demonstrationOilseeds}
                            iconPrimary={GroupsTwoToneIcon}
                            color={theme.palette.secondary.dark}
                        />
                    </Grid>
                </Grid>
                <Typography variant="h4" marginBottom="10px" marginTop="10px">
                    Input
                </Typography>
                <Grid container spacing={gridSpacing}>
                    <Grid item xs={12} lg={3}>
                        <UserCountCard
                            primary="Total beneficiaries received input"
                            secondary={inputData?.data.beneficiariesReceivedInput}
                            iconPrimary={GroupsTwoToneIcon}
                            color={theme.palette.info.light}
                        />
                    </Grid>
                    <Grid item xs={12} lg={3}>
                        <UserCountCard
                            primary="Number of male received inputs"
                            secondary={inputData?.data.maleBeneficiaries}
                            iconPrimary={GroupsTwoToneIcon}
                            color={theme.palette.info.light}
                        />
                    </Grid>
                    <Grid item xs={12} lg={3}>
                        <UserCountCard
                            primary="Number of female received inputs"
                            secondary={inputData?.data.femaleBeneficiaries}
                            iconPrimary={GroupsTwoToneIcon}
                            color={theme.palette.info.light}
                        />
                    </Grid>
                    <Grid item xs={12} lg={3}>
                        <UserCountCard
                            primary="Beneficiaries received more than one input"
                            secondary={inputData?.data.moreThanOneTraining}
                            iconPrimary={GroupsTwoToneIcon}
                            color={theme.palette.info.light}
                        />
                    </Grid>
                    <Grid item xs={12} lg={3}>
                        <UserCountCard
                            primary="Unique beneficiaries received inputs"
                            secondary={inputData?.data.uniqueBeneficiaries}
                            iconPrimary={GroupsTwoToneIcon}
                            color={theme.palette.info.light}
                        />
                    </Grid>
                    <Grid item xs={12} lg={3}>
                        <UserCountCard
                            primary="Number of youth received inputs"
                            secondary={inputData?.data.youthBeneficiaries}
                            iconPrimary={GroupsTwoToneIcon}
                            color={theme.palette.info.light}
                        />
                    </Grid>
                    <Grid item xs={12} lg={3}>
                        <UserCountCard
                            primary="Number of ethnic received inputs"
                            secondary={inputData?.data.ethnicityBeneficiaries}
                            iconPrimary={GroupsTwoToneIcon}
                            color={theme.palette.info.light}
                        />
                    </Grid>
                </Grid>
                <Typography variant="h4" marginBottom="10px" marginTop="10px">
                    Infrastructure
                </Typography>
                <Grid container spacing={gridSpacing}>
                    {(projectId === "-1" || projectId === "2") && <><Grid item xs={12} lg={3}>
                        <UserCountCard
                            primary="CFC"
                            secondary={infrastructureData?.data.cfc_count}
                            iconPrimary={GroupsTwoToneIcon}
                            color={theme.palette.error.main}
                        />
                    </Grid>
                        <Grid item xs={12} lg={3}>
                            <UserCountCard
                                primary="Seed village"
                                secondary={infrastructureData?.data.seedVillage}
                                iconPrimary={GroupsTwoToneIcon}
                                color={theme.palette.error.main}
                            />
                        </Grid>
                        <Grid item xs={12} lg={3}>
                            <UserCountCard
                                primary="Grass root"
                                secondary={infrastructureData?.data.seedVillage}
                                iconPrimary={GroupsTwoToneIcon}
                                color={theme.palette.error.main}
                            />
                        </Grid>
                        <Grid item xs={12} lg={3}>
                            <UserCountCard
                                primary="Solar Pump"
                                secondary={infrastructureData?.data.seedVillage}
                                iconPrimary={GroupsTwoToneIcon}
                                color={theme.palette.error.main}
                            />
                        </Grid>
                        <Grid item xs={12} lg={3}>
                            <UserCountCard
                                primary="Grass root"
                                secondary={infrastructureData?.data.seedVillage}
                                iconPrimary={GroupsTwoToneIcon}
                                color={theme.palette.error.main}
                            />
                        </Grid>
                        <Grid item xs={12} lg={3}>
                            <UserCountCard
                                primary="Outlet"
                                secondary={infrastructureData?.data.seedVillage}
                                iconPrimary={GroupsTwoToneIcon}
                                color={theme.palette.error.main}
                            />
                        </Grid>
                    </>}
                    {(projectId === "-1" || projectId === "1") && <><Grid item xs={12} lg={3}>
                        <UserCountCard
                            primary="Canal"
                            secondary={infrastructureData?.data.canalCount}
                            iconPrimary={GroupsTwoToneIcon}
                            color={theme.palette.error.main}
                        />
                    </Grid>
                        <Grid item xs={12} lg={3}>
                            <UserCountCard
                                primary="Buried Pipe"
                                secondary={infrastructureData?.data.buriedPipeCount}
                                iconPrimary={GroupsTwoToneIcon}
                                color={theme.palette.error.main}
                            />
                        </Grid></>}
                </Grid>
                <Grid marginTop="10px">
                    <Map />
                </Grid>
                <Benificiary division={divisionId} district={districtId} upazila={upazilaId} union={upazilaId} />
                <Demonstration division={divisionId} district={districtId} upazila={upazilaId} union={upazilaId} />
                <ProjectActivity division={divisionId} district={districtId} upazila={upazilaId} union={upazilaId} />
                <Infrastructure division={divisionId} district={districtId} upazila={upazilaId} union={upazilaId} />
            </CardContent>
        </MainCard>
    );
};

export default Index;
