import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Grid, Tab, Tabs } from '@mui/material';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import { gridSpacing } from 'store/constant';

// assets
import MemberIcon from '@mui/icons-material/VerifiedUser';
import GroupIcon from '@mui/icons-material/Group';
import DashboardTwoToneIcon from '@mui/icons-material/DashboardTwoTone';
import EventIcon from '@mui/icons-material/Event';
import KeyboardIcon from '@mui/icons-material/Keyboard';
import MemberRegister from './Member';
import GroupRegister from './Group';
import Dashboard from "./Dashboard";
import EventRegister from './Event';
import InputRegister from './Input';
import ProjectActivityMain from './ProjectActivity';
import WorkPlanMain from './WorkPlan';
import DemonstrationMain from './Demonstration/DemonstrationMain';
import InfrastructureMain from './Infrastructure/InfrastructureMain';
import ValidationMain from './Validation/ValidationMain';
import useAuth from 'hooks/useAuth';
import useUserPermission from 'hooks/consume_api/query/useUserPermission';
import LogFrameDefinition from 'views/LogFrameDefinition';
import LogFrame from 'views/LogFrame';
import ReportMain from './Report/ReportMain';
import { useLocation, useNavigate } from "react-router-dom";
// import ReportingPeriodType from './ReportingPeriodType';

// tabs panel
function TabPanel(props: { children: React.ReactElement; value: number; index: number }) {
    const { children, value, index, ...other } = props;
    return (
        <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`} {...other}>
            {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`
    };
}

// tabs option
let tabsOption = [
    {
        label: 'Dashboard',
        icon: <DashboardTwoToneIcon sx={{ fontSize: '1.3rem' }} />
    },
    {
        label: 'Member Register',
        icon: <MemberIcon sx={{ fontSize: '1.3rem' }} />
    },
    {
        label: 'Group Register',
        icon: <GroupIcon sx={{ fontSize: '1.3rem' }} />
    },
    {
        label: 'Project Activity',
        icon: <EventIcon sx={{ fontSize: '1.3rem' }} />
    },
    {
        label: 'Work plan',
        icon: <EventIcon sx={{ fontSize: '1.3rem' }} />
    },
    {
        label: 'Demonstration list',
        icon: <EventIcon sx={{ fontSize: '1.3rem' }} />
    },
    {
        label: 'Infrastructure list',
        icon: <EventIcon sx={{ fontSize: '1.3rem' }} />
    },
    {
        label: 'Validation',
        icon: <EventIcon sx={{ fontSize: '1.3rem' }} />
    },
    {
        label: 'Log Definition',
        icon: <EventIcon sx={{ fontSize: '1.3rem' }} />
    },
    {
        label: 'Log Frame',
        icon: <EventIcon sx={{ fontSize: '1.3rem' }} />
    },
    {
        label: 'Report',
        icon: <EventIcon sx={{ fontSize: '1.3rem' }} />
    }

];



const SacpRegisters = () => {
    const theme = useTheme();
    const [value, setValue] = React.useState<number>(0);
    const [roles, setRoles] = useState<number[]>([]);
    const { mutate, actionData } = useUserPermission();
    const { user } = useAuth();
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (user?.roles) {
            const ids: number[] = user.roles.map(item => item.id);
            setRoles(ids);
        }

    }, [user]);

    useEffect(() => {
        if (roles) mutate(roles);
    }, [roles])

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };
    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const indexValue = searchParams.get("index");
        if (indexValue) {
            setValue(Number(indexValue));
        }
    }, [])

    const checkPermission = (moduleName: string): boolean => {
        if (actionData) {
            return actionData.some(
                (item) => item.module_name === moduleName && item.action_name === 'View' && item.permission === true
            );
        }
        return false;
    };



    // const tabsOption: any[] = []
    // if (checkPermission("Member Register")) {
    //     tabsOption.push({
    //         label: 'Dashboard',
    //         icon: <DashboardTwoToneIcon sx={{ fontSize: '1.3rem' }} />

    //     })
    // }
    // if()

    const handleClick = (index: number) => {
        const currentParams = new URLSearchParams(location.search);
        currentParams.set("index", index.toString());
        const newUrl = `${location.pathname}?${currentParams.toString()}`;
        navigate(newUrl, { replace: true });
    }

    let a = tabsOption.filter(item => checkPermission(item.label));


    const getIndexByLabel = (label: string) => {
        return a.findIndex(item => item.label === label);
    };


    return (
        <MainCard>
            <Grid container spacing={gridSpacing}>
                <Grid item xs={12}>
                    <Tabs
                        value={value}
                        indicatorColor="primary"
                        textColor="primary"
                        onChange={handleChange}
                        aria-label="simple tabs example"
                        variant="scrollable"
                        sx={{
                            mb: 3,
                            '& a': {
                                minHeight: 'auto',
                                minWidth: 10,
                                py: 1.5,
                                px: 1,
                                mr: 2.25,
                                color: theme.palette.grey[600],
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'center'
                            },
                            '& a.Mui-selected': {
                                color: theme.palette.primary.main
                            },
                            '& .MuiTabs-indicator': {
                                bottom: 2
                            },
                            '& a > svg': {
                                marginBottom: '0px !important',
                                mr: 1.25
                            }
                        }}
                    >
                        {tabsOption
                            .filter((tab) => checkPermission(tab.label))
                            .map((tab, index) => (
                                <Tab
                                    key={index}
                                    onClick={() => handleClick(index)}
                                    // component={Link}
                                    // to="#"
                                    icon={tab.icon}
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: "center",
                                    }}
                                    label={tab.label}
                                    {...a11yProps(index)}
                                />
                            ))}
                    </Tabs>
                    <TabPanel value={value} index={0}>
                        <Dashboard />
                    </TabPanel>
                    {checkPermission('Member Register') && <TabPanel value={value} index={getIndexByLabel('Member Register')}>
                        <MemberRegister />
                    </TabPanel>}
                    {checkPermission('Group Register') && <TabPanel value={value} index={getIndexByLabel('Group Register')}>
                        <GroupRegister />
                    </TabPanel>}
                    {checkPermission('Project Activity') && <TabPanel value={value} index={getIndexByLabel('Project Activity')}>
                        <ProjectActivityMain />
                    </TabPanel>}
                    {checkPermission('Work plan') && <TabPanel value={value} index={getIndexByLabel('Work plan')}>
                        <WorkPlanMain />
                    </TabPanel>}
                    {checkPermission('Demonstration list') && <TabPanel value={value} index={getIndexByLabel('Demonstration list')}>
                        <DemonstrationMain />
                    </TabPanel>}
                    {checkPermission('Infrastructure list') && <TabPanel value={value} index={getIndexByLabel('Infrastructure list')}>
                        <InfrastructureMain />
                    </TabPanel>}
                    {checkPermission('Validation') && <TabPanel value={value} index={getIndexByLabel('Validation')}>
                        <ValidationMain />
                    </TabPanel>}
                    {checkPermission('Log Definition') && <TabPanel value={value} index={getIndexByLabel('Log Definition')}>
                        <LogFrameDefinition projectID={61} />
                    </TabPanel>}
                    {checkPermission('Log Frame') && <TabPanel value={value} index={getIndexByLabel('Log Frame')}>
                        <LogFrame projectID={61} />
                    </TabPanel>}
                    {checkPermission('Report') && <TabPanel value={value} index={getIndexByLabel('Report')}>
                        <ReportMain />
                    </TabPanel>}

                </Grid>
            </Grid>
        </MainCard>
    );
};

export default SacpRegisters;
