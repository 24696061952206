import { useQuery } from '@tanstack/react-query';
import { getDemoOrInfraMapData } from 'services/reactQueryservices';
import { demoOrInfraMapDataKey } from 'react_query/query-keys';

const useDemoOrInfraDataForMap = (type: string) => {
    const { data, isFetching, error } = useQuery({
        queryKey: [demoOrInfraMapDataKey, type],
        queryFn: () => getDemoOrInfraMapData(type),
        enabled: !!type
    });

    return {
        data,
        isFetching,
        error
    };
}

export default useDemoOrInfraDataForMap