import React from 'react';
import { dashboardBeneficiary } from 'services/reactQueryservices';
import { useQuery } from '@tanstack/react-query';
import { dashboardBeneficiaryKey } from '../../../react_query/query-keys';
const useDashboardBenificiary = (division: string, district: string, upazila: string, union: string, year: string, projectId: string) => {

    const { data, isFetching, isFetched, isError, error } = useQuery({
        queryKey: [dashboardBeneficiaryKey, division, district, upazila, union, year, projectId],
        queryFn: () => dashboardBeneficiary(division, district, upazila, union, year, projectId),
        staleTime: 1 * 1000
    });

    return {
        data,
        isFetching,
        isFetched,
        isError,
        error
    };
};

export default useDashboardBenificiary;
