import useInfrastructureChart from 'hooks/consume_api/query/useInfrastructureChart'
import React from 'react'
import { Grid, Typography } from '@mui/material';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
interface props {
    division: string,
    district: string,
    upazila: string,
    union: string
}
const Infrastructure: React.FC<props> = ({ division, district, upazila, union }) => {
    const { data } = useInfrastructureChart();
    return (
        data?.data?.length ? (
            <>
                <Typography variant='h3' sx={{ marginTop: "20px" }}>Infrastructure Report</Typography>
                <Grid marginTop="10px" item xs={12}>
                    <TableContainer component={Paper} sx={{ border: '1px solid black', borderRadius: '1px' }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ borderBottom: '1px solid black', borderRight: '1px solid black' }}>Sub Activity</TableCell>
                                    <TableCell sx={{ borderBottom: '1px solid black', borderRight: '1px solid black' }}>Male</TableCell>
                                    <TableCell sx={{ borderBottom: '1px solid black', borderRight: '1px solid black' }}>Female</TableCell>
                                    <TableCell sx={{ borderBottom: '1px solid black' }}>Total</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell sx={{ borderBottom: '1px solid black', borderRight: '1px solid black' }}>Beneficiaries</TableCell>
                                    <TableCell sx={{ borderBottom: '1px solid black', borderRight: '1px solid black' }}>{data?.data[0].male_count}</TableCell>
                                    <TableCell sx={{ borderBottom: '1px solid black', borderRight: '1px solid black' }}>{data?.data[0].female_count}</TableCell>
                                    <TableCell sx={{ borderBottom: '1px solid black' }}>{Number(data?.data[0].male_count) + Number(data?.data[0].female_count)}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{ borderRight: '1px solid black' }}>Beneficiaries</TableCell>
                                    <TableCell sx={{ borderRight: '1px solid black' }}>{data?.data[1].male_count}</TableCell>
                                    <TableCell sx={{ borderRight: '1px solid black' }}>{data?.data[1].female_count}</TableCell>
                                    <TableCell >{Number(data?.data[1].male_count) + Number(data?.data[1].female_count)}</TableCell>
                                </TableRow>

                            </TableBody>
                        </Table>
                    </TableContainer>

                </Grid>
            </>
        ) : null
    )

}

export default Infrastructure