import { Grid, Typography } from '@mui/material';
import useDemonstrationChartData from 'hooks/consume_api/query/useDemonstrationChartData'
import React from 'react';
import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
interface props {
    division: string,
    district: string,
    upazila: string,
    union: string
}
const Demonstration: React.FC<props> = ({ division, district, upazila, union }) => {
    const { data } = useDemonstrationChartData(division, district, upazila, union);

    return (
        <>
            <Typography sx={{ marginTop: "40px", marginBottom: "10px" }} variant='h3'>Demonstration</Typography>
            <Grid container >
                {data && (
                    <>

                        <Grid marginTop="10px" marginBottom="10px" item xs={12}>
                            <TableContainer component={Paper} sx={{ border: '1px solid black', borderRadius: '1px' }}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell sx={{ borderBottom: '1px solid black', borderRight: '1px solid black' }}>Total Beneficiary</TableCell>
                                            <TableCell sx={{ borderBottom: '1px solid black', borderRight: '1px solid black' }}>Male Beneficiary</TableCell>
                                            <TableCell sx={{ borderBottom: '1px solid black', borderRight: '1px solid black' }}>Female Beneficiary</TableCell>
                                            <TableCell sx={{ borderBottom: '1px solid black' }}>Youth Beneficiary</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>

                                        <TableRow>
                                            <TableCell sx={{ borderRight: '1px solid black' }}>{data?.data?.male?.series[0]}</TableCell>
                                            <TableCell sx={{ borderRight: '1px solid black' }}>{data?.data?.male?.series[1]}</TableCell>
                                            <TableCell sx={{ borderRight: '1px solid black' }}>{data?.data?.female?.series[1]}</TableCell>
                                            <TableCell >{data?.data?.female?.series[1]}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>

                        </Grid>
                        <Grid item xs={4}>
                            <Chart
                                options={data?.data?.male?.pieOption as ApexOptions}
                                series={data?.data?.male?.series}
                                type="pie"
                                height={250}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Chart
                                options={data?.data?.female?.pieOption as ApexOptions}
                                series={data?.data?.female?.series}
                                type="pie"
                                height={250}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <Chart
                                options={data?.data?.youth?.pieOption as ApexOptions}
                                series={data?.data?.youth?.series}
                                type="pie"
                                height={250}
                            />
                        </Grid>
                        <Grid marginTop="10px" item xs={12}>
                            <TableContainer component={Paper} sx={{ border: '1px solid black', borderRadius: '1px' }}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell sx={{ borderBottom: '1px solid black', borderRight: '1px solid black' }}>SubActivity</TableCell>
                                            <TableCell sx={{ borderBottom: '1px solid black', borderRight: '1px solid black' }}>Male</TableCell>
                                            <TableCell sx={{ borderBottom: '1px solid black', borderRight: '1px solid black' }}>Female</TableCell>
                                            <TableCell sx={{ borderBottom: '1px solid black' }}>Total</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {/* Example Rows */}
                                        <TableRow>
                                            <TableCell sx={{ borderBottom: '1px solid black', borderRight: '1px solid black' }}>Farmers Field trial on stress tolerant varieties/ adaptation technologies of fruits, vegetables, oilseed, cereal crops, etc. with production programme of promising technologies</TableCell>
                                            <TableCell sx={{ borderBottom: '1px solid black', borderRight: '1px solid black' }}>{data?.data?.report[0].male_count}</TableCell>
                                            <TableCell sx={{ borderBottom: '1px solid black', borderRight: '1px solid black' }}>{data?.data?.report[0].female_count}</TableCell>
                                            <TableCell sx={{ borderBottom: '1px solid black' }}>{Number(data?.data?.report[0].male_count) + Number(data?.data?.report[0].female_count)}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell sx={{ borderBottom: '1px solid black', borderRight: '1px solid black' }}>Breeder seed production of pulses, oilseeds, vegetables and other potential HVCs for BADC, seed villages, research, production programmes and demonstrations</TableCell>
                                            <TableCell sx={{ borderBottom: '1px solid black', borderRight: '1px solid black' }}>{data?.data?.report[1].male_count}</TableCell>
                                            <TableCell sx={{ borderBottom: '1px solid black', borderRight: '1px solid black' }}>{data?.data?.report[1].female_count}</TableCell>
                                            <TableCell sx={{ borderBottom: '1px solid black' }}>{Number(data?.data?.report[1].male_count) + Number(data?.data?.report[1].female_count)}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell sx={{ borderRight: '1px solid black' }}>Farmers Field Trial on appropriate scale mechanization</TableCell>
                                            <TableCell sx={{ borderRight: '1px solid black' }}>{data?.data?.report[2].male_count}</TableCell>
                                            <TableCell sx={{ borderRight: '1px solid black' }}>{data?.data?.report[2].female_count}</TableCell>
                                            <TableCell >{Number(data?.data?.report[2].male_count) + Number(data?.data?.report[2].female_count)}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>

                        </Grid>
                    </>

                )}
            </Grid>
        </>
    )
}

export default Demonstration