// material-ui
import { useTheme, styled } from '@mui/material/styles';
import {
    Avatar,
    Button,
    Card,
    CardContent,
    Chip,
    Divider,
    Grid,
    List,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText,
    Stack,
    Typography
} from '@mui/material';

// assets
import { IconBrandTelegram, IconBuildingStore, IconMailbox, IconPhoto } from '@tabler/icons';
import User1 from 'assets/images/users/user-round.svg';
import useNotification from 'hooks/consume_api/query/useNotification';

// styles
const ListItemWrapper = styled('div')(({ theme }) => ({
    cursor: 'pointer',
    padding: 16,
    '&:hover': {
        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.primary.light
    },
    '& .MuiListItem-root': {
        padding: 0
    }
}));

// ==============================|| NOTIFICATION LIST ITEM ||============================== //

const NotificationList = ({ onClick, user }: any) => {
    const theme = useTheme();

    const chipSX = {
        height: 24,
        padding: '0 6px'
    };
    const chipErrorSX = {
        ...chipSX,
        color: theme.palette.orange.dark,
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.orange.light,
        marginRight: '5px'
    };

    const chipWarningSX = {
        ...chipSX,
        color: theme.palette.warning.dark,
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.warning.light
    };

    const chipSuccessSX = {
        ...chipSX,
        color: theme.palette.success.dark,
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.success.light,
        height: 28
    };
    const { data } = useNotification()

    return (
        <Grid sx={{ width: "400px" }}>
            {data && <List>
                <ListItem>
                    <ListItemText primary={`Total Request :` + data?.data.totalValidationRequest} />
                </ListItem>
                <ListItem>
                    <ListItemText primary={`Total Accepted :` + data?.data.totalAccepted} />
                </ListItem>
                <ListItem>
                    <ListItemText primary={`Total Rejected :` + data?.data.rejection} />
                </ListItem>
                <ListItem>
                    <ListItemText primary={`Yet to be validated :` + data?.data.yetTobeValidated} />
                </ListItem>
            </List>}
        </Grid>
    );
};

export default NotificationList;
