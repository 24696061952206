import { _deserailize } from 'utils/Deserialize';
import {
    deleteCollectionFrequencyOfProjectURL,
    deleteReportingPeriodOfProjectURL,
    deleteRPTOfProjectURL,
    deleteUOMOfProjectURL,
    formMediaCreateURL,
    getCollectionFrequencyListOfProjectURL,
    getReportingPeriodListOfProjectURL,
    getRPTListOfProjectURL,
    getUOMListOfProjectURL,
    updateCollectionFrequencyOfProjectURL,
    updateReportingPeriodOfProjectURL,
    updateRPTOfProjectURL,
    updateUOMOfProjectURL
} from 'utils/serverUrls';
import axiosServices from 'utils/axiosServices';
import axiosFormServices from 'utils/axiosFormServices';
import { AvailableEventMemberListPayload, AvailableInputMemberListPayload } from '../types';
import { Deserializer } from 'jsonapi-serializer';
import { saveAs } from 'file-saver';
export const getGuardianListBySearch = async (data: any) => {
    const response = await axiosServices({
        url: `/api/v1/administration/getGuardianList`,
        method: 'post',
        data
    });
    return response?.data?._value;
};

export const getMenuList = async () => {
    const response = await axiosServices({
        url: `/api/v1/administration/getMenuList`,
        method: 'get'
    });
    return response?.data?._value;
};

export const deleteBatch = async (id: string) => {
    const response = await axiosServices({
        url: `/api/v1/batch/deleteBatch/${id}`,
        method: 'get'
    });

    return response?.data?._value;
};

export const getActivityListByProjectId = async (id: string) => {
    const response = await axiosServices({
        url: `/msurvey/api/logframe/activity/list?projectId=${id}`,
        method: 'get'
    });
    return response.data;
};

export const createActivity = async (data: any) => {
    return axiosServices({
        url: `/msurvey/api/logframe/activity/update`,
        method: 'post',
        data
    });
};

export const updateActivityResponsiblePerson = async (data: any) => {
    return axiosServices({
        url: `/msurvey/api/logframe/activity/responsible-person/update`,
        method: 'post',
        data
    });
};

export const addIndicators = async (data: any) => {
    return axiosServices({
        url: `/msurvey/api/logframe/activity/indicator/update`,
        method: 'post',
        data
    });
};
export const addExpenditure = async (data: any) => {
    return axiosServices({
        url: `/msurvey/api/logframe/activity/add-expence`, // Adjust the endpoint according to your API
        method: 'post',
        data
    });
};
export const getActivityStatusList = async () => {
    const response = await axiosServices({
        url: `/msurvey/api/logframe/activity/activity-status/list`,
        method: 'get'
    });
    return response?.data;
};
export const updateActivityStatus = async (data: any) => {
    const response = await axiosServices({
        url: `/msurvey/api/logframe/activity/update-activity-status`,
        method: 'post',
        data
    });
    return response?.data;
};
export const getActivityPhaseList = async () => {
    const response = await axiosServices({
        url: `/msurvey/api/logframe/activity/activity-phase/list`,
        method: 'get'
    });
    return response?.data;
};
export const getTeamUserList = async (projectId: string) => {
    const response = await axiosServices({
        url: `/msurvey/api/logframe/activity/responsible-person/list?projectId=${projectId}`,
        method: 'get'
    });
    return response?.data;
};
export const getActivityDetail = async (id: string) => {
    const response = await axiosServices({
        url: `/msurvey/api/logframe/activity/detail?activityId=${id}`,
        method: 'get'
    });
    return response?.data;
};
export const getIndicatorListByProjectId = async (id: string) => {
    const response = await axiosServices({
        url: `/msurvey/api/logframe/indicator/list?projectId=${id}&getAll=true`,
        method: 'get'
    });
    return response?.data;
};

export const getDemoOrInfraMapData = async (type: string) => {
    const response = await axiosServices({
        url: `/msurvey/api/sacp/demo-infra-map-data?type=${type}`,
        method: 'get'
    });
    return response?.data;
};

export const getIndicatorListByActivityId = async (id: string) => {
    const response = await axiosServices({
        url: `/msurvey/api/logframe/activity/indicator/list?activityId=${id}`,
        method: 'get'
    });
    return response?.data;
};
export const getExpenditureListByActivityId = async (id: string) => {
    const response = await axiosServices({
        url: `/msurvey/api/logframe/activity/expense-list?activityId=${id}`,
        method: 'get'
    });
    return response?.data;
};

export const getUomListByProjectId = async (id: string) => {
    const params = { projectId: id };
    const response = await axiosServices.get(`${getUOMListOfProjectURL}`, { params });
    return await _deserailize(response?.data);
};

export const createOrUpdateUOM = async (payload: any) => {
    const response = await axiosServices.post(`${updateUOMOfProjectURL}`, payload);
    return response?.data;
};

export const deleteUOM = async (payload: any) => {
    const response = await axiosServices.post(`${deleteUOMOfProjectURL}`, payload);
    return response?.data;
};

export const getCollectionFrequencyListByProjectId = async (id: string) => {
    const params = { projectId: id };
    const response = await axiosServices.get(`${getCollectionFrequencyListOfProjectURL}`, { params });
    return await _deserailize(response?.data);
};

export const createOrUpdateCollectionFrequency = async (payload: any) => {
    const response = await axiosServices.post(`${updateCollectionFrequencyOfProjectURL}`, payload);
    return response?.data;
};

export const deleteCollectionFrequency = async (payload: any) => {
    const response = await axiosServices.post(`${deleteCollectionFrequencyOfProjectURL}`, payload);
    return response?.data;
};

export const getRPTListByProjectId = async (id: string) => {
    const params = { projectId: id };
    const response = await axiosServices.get(`${getRPTListOfProjectURL}`, { params });
    return response?.data;
};

export const createOrUpdateRPT = async (payload: any) => {
    const response = await axiosServices.post(`${updateRPTOfProjectURL}`, payload);
    return response?.data;
};

export const deleteRPT = async (payload: any) => {
    const response = await axiosServices.post(`${deleteRPTOfProjectURL}`, payload);
    return response?.data;
};

export const getReportingPeriodListByProjectId = async (id: string) => {
    const params = { reportingPeriodTypeId: id };
    const response = await axiosServices.get(`${getReportingPeriodListOfProjectURL}`, { params });
    return response?.data;
};

export const createOrUpdateReportingPeriod = async (payload: any) => {
    const response = await axiosServices.post(`${updateReportingPeriodOfProjectURL}`, payload);
    return response?.data;
};

export const deleteReportingPeriod = async (payload: any) => {
    const response = await axiosServices.post(`${deleteReportingPeriodOfProjectURL}`, payload);
    return response?.data;
};

export const getMediaListByProjectFormId = async (id: string) => {
    const params = { formId: id };
    const response = await axiosServices.get(`${getUOMListOfProjectURL}`, { params });
    return await _deserailize(response?.data);
};

export const createProjectFormMedia = async (payload: any) => {
    const response = await axiosFormServices.post(`${formMediaCreateURL}`, payload);
    return response?.data;
};

////////////////////////////// SACP /////////////////////////////////////////////

export const getGroupMemberList = async (id: string) => {
    const response = await axiosServices({
        url: `/msurvey/api/sacp/get-group-member-list/${id}`,
        method: 'get'
    });
    return response?.data.data;
};

export const getAvailableUsersList = async (id: string) => {
    const response = await axiosServices({
        url: `/msurvey/api/sacp/get-available-user-list/${id}`,
        method: 'get'
    });
    return response?.data.data;
};

export const addGroupMember = async (data: any) => {
    const response = await axiosServices({
        url: `/msurvey/api/sacp/add-group-member`,
        method: 'post',
        data
    });
    return response?.data;
};
export const removeGroupMember = async (data: any) => {
    const response = await axiosServices({
        url: `/msurvey/api/sacp/remove-group-member`,
        method: 'post',
        data
    });
    return response?.data;
};

export const updateGroupMemberRole = async (data: any) => {
    const response = await axiosServices({
        url: `/msurvey/api/sacp/update-member-role`,
        method: 'post',
        data
    });
    return response?.data;
};

export const getSACPGroupDetail = async (id: string) => {
    const response = await axiosServices({
        url: `/msurvey/api/sacp/get-group-details/${id}`,
        method: 'get'
    });
    return response?.data.data;
};

export const getGeoLocation = async (geoType: number, parent: number | '') => {
    const response = await axiosServices({
        url: `/msurvey/api/geo/get-geo-by-parent`,
        method: 'post',
        data: { geoType, parent }
    });
    return response?.data;
};

export const getEventList = async (page: number, limit: number) => {
    const response = await axiosServices({
        url: `/msurvey/api/sacp/get-event-list`,
        method: 'post',
        data: { page, limit }
    });
    return response?.data.data.data;
};
export const approveEvent = async (data: any) => {
    const response = await axiosServices({
        url: `/msurvey/api/sacp/approve-event`,
        method: 'post',
        data
    });
    return response?.data;
};
export const getAvailableEventUserList = async (data: AvailableEventMemberListPayload) => {
    const response = await axiosServices({
        url: `/msurvey/api/sacp/get-available-event-user-list`,
        method: 'post',
        data: data
    });
    return response?.data.data;
};

export const getEventDetails = async (id: string) => {
    const response = await axiosServices({
        url: `/msurvey/api/sacp/get-event-details/${id}`,
        method: 'get'
    });
    return response?.data.data;
};
export const getEventMemberList = async (id: string) => {
    const response = await axiosServices({
        url: `/msurvey/api/sacp/get-event-member-list/${id}`,
        method: 'get'
    });
    return response?.data.data;
};

export const addEventMember = async (data: any) => {
    const response = await axiosServices({
        url: `/msurvey/api/sacp/add-event-member`,
        method: 'post',
        data
    });
    return response?.data;
};
export const removeEventMember = async (data: { eventId: string; memberId: string }) => {
    const response = await axiosServices({
        url: `/msurvey/api/sacp/delete-event-member/${data.eventId}/${data.memberId}`,
        method: 'delete'
    });
    return response?.data;
};

export const getInputList = async (page: number, limit: number) => {
    const response = await axiosServices({
        url: `/msurvey/api/sacp/get-input-list`,
        method: 'post',
        data: { page, limit }
    });
    return response?.data.data.data;
};
export const approveInput = async (data: any) => {
    const response = await axiosServices({
        url: `/msurvey/api/sacp/approve-input`,
        method: 'post',
        data
    });
    return response?.data;
};
export const getInputMemberList = async (id: string) => {
    const response = await axiosServices({
        url: `/msurvey/api/sacp/get-input-member-list/${id}`,
        method: 'get'
    });
    return response?.data.data;
};

export const getInputDetails = async (id: string) => {
    const response = await axiosServices({
        url: `/msurvey/api/sacp/get-input-details/${id}`,
        method: 'get'
    });
    return response?.data.data;
};
export const getAvailableInputUserList = async (data: AvailableInputMemberListPayload) => {
    const response = await axiosServices({
        url: `/msurvey/api/sacp/get-available-input-user-list`,
        method: 'post',
        data: data
    });
    return response?.data.data;
};
export const addInputMember = async (data: any) => {
    const response = await axiosServices({
        url: `/msurvey/api/sacp/add-input-members`,
        method: 'post',
        data
    });
    return response?.data;
};
export const removeInputMember = async (data: { inputId: string; memberId: string }) => {
    const response = await axiosServices({
        url: `/msurvey/api/sacp/delete-input-member/${data.inputId}/${data.memberId}`,
        method: 'delete'
    });
    return response?.data;
};

export const getCardData = async (data = {}) => {
    const response = await axiosServices({
        url: `/msurvey/api/sacp/get-card-data`,
        method: 'post',
        data
    });
    return response?.data.data;
};
export const getChartData = async (data: any) => {
    const response = await axiosServices({
        url: `/msurvey/api/sacp/get-chart-data`,
        method: 'post',
        data
    });
    return response?.data.data;
};

export const getProjectActivityList = async (
    page: number,
    row: number,
    search: string,
    division: string,
    district: string,
    upazila: string
) => {
    const response = await axiosServices({
        method: 'get',
        url: `/msurvey/api/sacp/get-project-list?page=${page}&limit=${row}&search=${search}&division=${division}&district=${district}&upazila=${upazila}`
    });
    return response?.data;
};

export const getMemberListForProjectActivityList = async (
    divisionId: number,
    districtId: number,
    upazilaId: number,
    unionId: number,
    beneficiaryTypeId: number,
    page: number,
    pageSize: number,
    projectId: string | null,
    name: string
) => {
    const response = await axiosServices({
        method: 'get',
        url: `/msurvey/api/sacp/get-member-for-project-activity?page=${page}&pageSize=${pageSize}&division=${divisionId}&district=${districtId}&upazila=${upazilaId}&union=${unionId}&beneficiaryType=${beneficiaryTypeId}&projectId=${projectId}&name=${name}`
    });
    return response?.data;
};

export const getProjectMemberList = async (projectInsanceId: string | null) => {
    const response = await axiosServices({
        method: 'get',
        url: `/msurvey/api/sacp/get-project-member-list?projectId=${projectInsanceId}`
    });
    return response?.data;
};

export const getProjectMediaList = async (projectId: string) => {
    const response = await axiosServices({
        method: 'get',
        url: `/msurvey/api/sacp/get-media-list?projectID=${projectId}`
    });
    return response?.data;
};

export const deleteProjectMember = async (id: string | number) => {
    const response = await axiosServices({
        method: 'post',
        url: `/msurvey/api/sacp/delete-project-member`,
        data: {
            id: id
        }
    });
    return response?.data;
};

export const addMemberForProject = async (data: any) => {
    const response = await axiosServices({
        method: 'post',
        url: `/msurvey/api/sacp//add-project-member`,
        data: data
    });
    return response?.data;
};

export const deleteProject = async (instanceId: string | number) => {
    const response = await axiosServices({
        method: 'post',
        url: `/msurvey/api/sacp/delete-project-from-logger-instance`,
        data: {
            instanceId: instanceId
        }
    });

    return response?.data;
};

export const addProjectMedia = async (data: any) => {
    const response = await axiosServices({
        method: 'post',
        url: '/msurvey/api/sacp/add-media',
        data: data
    });

    return response?.data;
};

export const handleDeletePermissionOfProjectMember = async (projectId: string | number) => {
    const response = await axiosServices({
        method: 'post',
        url: '/msurvey/api/sacp/project-member/delete-permission',
        data: {
            projectId: projectId
        }
    });
    return response?.data;
};

export const handleAttendancePdf = async (projectId: string) => {
    const response = await axiosServices({
        method: 'get',
        url: `/msurvey/api/sacp/pdf-attendance?project_uid=${projectId}`,
        responseType: 'blob'
    });

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;

    link.setAttribute('download', 'attendance.pdf');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

export const handleMasterRollPdf = async (projectId: string) => {
    const response = await axiosServices({
        method: 'get',
        url: `/msurvey/api/sacp/pdf-master-roll?project_uid=${projectId}`,
        responseType: 'blob'
    });

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;

    link.setAttribute('download', 'masterRoll.pdf');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

export const memberProfileList = async (memberId: string) => {
    const response = await axiosServices({
        method: 'get',
        url: `/msurvey/api/sacp/project-member-activity?memberUid=${memberId}`
    });
    return response?.data;
};

export const subActivityCountBadc = async (subActivity: string) => {
    const response = await axiosServices({
        method: 'get',
        url: `/msurvey/api/sacp/sub-activity-count/badc?subActivity=${subActivity}`
    });
    // console.log(response?.data, '======', subActivity, '--');
    return response?.data;
};

export const subActivityCountGain = async (subActivity: string, startYear: string, endYear: string) => {
    const response = await axiosServices({
        method: 'get',
        url: `/msurvey/api/sacp/sub-activity-count/gain?subActivity=${subActivity}&startYear=${startYear}&endYear=${endYear}`
    });
    return response?.data;
};

export const subActivityCountDam = async (subActivity: string, startYear: string, endYear: string) => {
    const response = await axiosServices({
        method: 'get',
        url: `/msurvey/api/sacp/sub-activity-count/dam?subActivity=${subActivity}&startYear=${startYear}&endYear=${endYear}`
    });
    return response?.data;
};

export const subActivityCountDae = async (subActivity: string, startYear: string, endYear: string) => {
    const response = await axiosServices({
        method: 'get',
        url: `/msurvey/api/sacp/sub-activity-count/dae?subActivity=${subActivity}&startYear=${startYear}&endYear=${endYear}`
    });
    return response?.data;
};

export const subActivityCountBari = async (subActivity: string, startYear: string, endYear: string) => {
    const response = await axiosServices({
        method: 'get',
        url: `/msurvey/api/sacp/sub-activity-count/Bari?subActivity=${subActivity}&startYear=${startYear}&endYear=${endYear}`
    });
    return response?.data;
};

export const subActivityCountFao = async (subActivity: string, startYear: string, endYear: string) => {
    const response = await axiosServices({
        method: 'get',
        url: `/msurvey/api/sacp/sub-activity-count/fao?subActivity=${subActivity}&startYear=${startYear}&endYear=${endYear}`
    });
    return response?.data;
};

export const workPlanMainCreation = async (data: any) => {
    const response = await axiosServices({
        url: `/msurvey/api/sacp/work-plan/main`,
        method: 'post',
        data
    });
    return response?.data;
};

export const workPlanFiscalYearCreation = async (data: any) => {
    const response = await axiosServices({
        url: `/msurvey/api/sacp/work-plan/fiscalYear`,
        method: 'post',
        data
    });
    return response?.data;
};

export const workPlanDelete = async (data: any) => {
    const response = await axiosServices({
        url: `/msurvey/api/sacp/work-plan/delete`,
        method: 'post',
        data
    });
    return response?.data;
};

export const location = async (parent: string) => {
    const response = await axiosServices({
        url: `/msurvey/api/sacp/location?parent=${parent}`,
        method: 'get'
    });
    return response?.data;
};

export const fiscalYearCreate = async (data: any) => {
    const response = await axiosServices({
        url: `/msurvey/api/sacp/fiscal-year/add`,
        method: 'post',
        data
    });
    return response?.data;
};

export const fiscalYearListByAgency = async (agency: string) => {
    const response = await axiosServices({
        url: `/msurvey/api/sacp/fiscal-year-list-by-agency?agency=${agency}`,
        method: 'get'
    });
    return response?.data;
};

export const workPlanListByAgencyAndYear = async (agency: string, startYear: string, endYear: string) => {
    const response = await axiosServices({
        url: `/msurvey/api/sacp//work-plan-details?agency=${agency}&startYear=${startYear}&endYear=${endYear}`,
        method: 'get'
    });
    return response?.data;
};

export const workPlanUpdate = async (data: any) => {
    const response = await axiosServices({
        url: `/msurvey/api/sacp/work-plan/update`,
        method: 'post',
        data
    });
    return response?.data;
};

export const memberInstanceDelete = async (id: string) => {
    const response = await axiosServices({
        url: `/msurvey/api/sacp/member-instance-delete`,
        method: 'post',
        data: {
            id
        }
    });
    return response?.data;
};

export const memberList = async (obj: any) => {
    const response = await axiosServices({
        url: `/msurvey/api/sacp/get-member-list/`,
        method: 'post',
        data: {
            obj
        }
    });
    return response?.data;
};

export const dashboardBeneficiary = async (
    division: string,
    district: string,
    upazila: string,
    union: string,
    year: string,
    projectId: string
) => {
    const response = await axiosServices({
        url: `/msurvey/api/sacp/dashboard/beneficiary?division=${division}&district=${district}&upazila=${upazila}&union=${union}&year=${year}&projectId=${projectId}`,
        method: 'get'
    });
    return response?.data;
};

export const dashboardTraining = async (
    division: string,
    district: string,
    upazila: string,
    union: string,
    year: string,
    project: string,
    agency: string
) => {
    const response = await axiosServices({
        url: `/msurvey/api/sacp/dashboard/training?division=${division}&district=${district}&upazila=${upazila}&union=${union}&year=${year}&project=${project}&agency=${agency}`,
        method: 'get'
    });
    return response?.data;
};

export const dashboardDemonstration = async (
    project: string,
    agency: string,
    division: string,
    district: string,
    upazila: string,
    year: string
) => {
    const response = await axiosServices({
        url: `/msurvey/api/sacp/dashboard/demonstration?division=${division}&district=${district}&upazila=${upazila}&year=${year}&project=${project}&agency=${agency}`,
        method: 'get'
    });
    return response?.data;
};

export const dashboardInput = async (
    project: string,
    agency: string,
    division: string,
    district: string,
    upazila: string,
    year: string
) => {
    const response = await axiosServices({
        url: `/msurvey/api/sacp/dashboard/input?division=${division}&district=${district}&upazila=${upazila}&year=${year}&project=${project}&agency=${agency}`,
        method: 'get'
    });
    return response?.data;
};

export const dashboardInfrastructure = async (project: string, agency: string, division: string, district: string, upazila: string) => {
    const response = await axiosServices({
        url: `/msurvey/api/sacp/dashboard/infrastructure?division=${division}&district=${district}&upazila=${upazila}&project=${project}&agency=${agency}`,
        method: 'get'
    });
    return response?.data;
};

export const getNotification = async () => {
    const response = await axiosServices({
        url: `/msurvey/api/sacp//notification`,
        method: 'get'
    });
    return response?.data;
};

export const demonstrationList = async (page: number) => {
    const response = await axiosServices({
        url: `/msurvey/api/sacp/demonstration/list?page=${page}`,
        method: 'get'
    });
    return response?.data;
};

export const infrastructureList = async (tag: string) => {
    const response = await axiosServices({
        url: `/msurvey/api/sacp/infrastructure-list?tag=${tag}`,
        method: 'get'
    });
    return response?.data;
};

export const validationList = async () => {
    const response = await axiosServices({
        url: `/msurvey/api/sacp/validation-list`,
        method: 'get'
    });
    return response?.data;
};

export const validationListBySubActivity = async (sub_activity: string) => {
    const response = await axiosServices({
        url: `/msurvey/api/sacp/validation-list-by-subactivity?sub_activity=${sub_activity}`,
        method: 'get'
    });
    return response?.data;
};

export const validationSingleSubmission = async (data: { instanceId: string | number; isValidate: boolean }) => {
    return axiosServices({
        url: `/msurvey/api/sacp/validation-single-submission`,
        method: 'post',
        data
    });
};

export const validationRejection = async (project_id: string) => {
    return axiosServices({
        url: `/msurvey/api/sacp/validation-rejection`,
        method: 'post',
        data: {
            project_id
        }
    });
};

export const projectDetails = async (instanceId: string) => {
    const response = await axiosServices({
        url: `/msurvey/api/sacp/project-details?instanceId=${instanceId}`,
        method: 'get'
    });
    return response?.data;
};

export const validationBulkSubmission = async (data: { sub_activity: string }) => {
    return axiosServices({
        url: `/msurvey/api/sacp/validation-submission`,
        method: 'post',
        data
    });
};

export const groupMemberDelete = async (instanceId: number) => {
    const response = await axiosServices({
        url: `/msurvey/api/sacp/group-delete?instanceId=${instanceId}`,
        method: 'get'
    });
    return response?.data;
};

export const roleList = async () => {
    const response = await axiosServices({
        url: `/msurvey/api/sacp/role-list`,
        method: 'get'
    });
    return response?.data;
};

export const permissionList = async (role_id: number) => {
    const response = await axiosServices({
        url: `/msurvey/api/sacp/permission-list?role_id=${role_id}`,
        method: 'get'
    });
    return response?.data;
};

export const permissionChange = async (obj: any) => {
    return axiosServices({
        url: `/msurvey/api/sacp/permission-change`,
        method: 'post',
        data: obj
    });
};

export const userPermission = async (obj: any[]) => {
    return axiosServices({
        url: `/msurvey/api/sacp/user-permission`,
        method: 'post',
        data: { roles: obj }
    });
};

export const getFrequencyList = async (id: string) => {
    const response = await axiosServices({
        url: `/msurvey/api/logframe/collection-frequency/list?projectId=${id}`,
        method: 'get'
    });
    const deserializer = await new Deserializer({
        keyForAttribute: 'camelCase'
    }).deserialize(response.data);

    const mapData = deserializer.map((val: any) => ({
        id: val.id,
        frequency: val.frequency,
        projectId: val.projectId
    }));
    return mapData;
};

export const addFrequency = async (data: any) => {
    return axiosServices({
        url: `/msurvey/api/logframe/collection-frequency/update`,
        method: 'post',
        data
    });
};

export const getUnitOfMeasurementList = async (id: string) => {
    const response = await axiosServices({
        url: `/msurvey/api/logframe/uom/list?projectId=${id}`,
        method: 'get'
    });
    const deserializer = await new Deserializer({
        keyForAttribute: 'camelCase'
    }).deserialize(response.data);

    const mapData = deserializer.map((val: any) => ({
        id: val.id,
        unitOfMeasurement: val.name,
        projectId: val.projectId
    }));
    return mapData;
};

export const addUnitOfMeasurement = async (data: any) => {
    return axiosServices({
        url: `/msurvey/api/logframe/uom/update`,
        method: 'post',
        data
    });
};

export const subActivity = async () => {
    const response = await axiosServices({
        url: `/msurvey/api/sacp/sub-activity`,
        method: 'get'
    });
    return response?.data;
};

export const frequenciesByLogFrame = async (logFrameId: string) => {
    const response = await axiosServices({
        url: `/msurvey/api/logframe/frequency-by-logframe-id?logframeId=${logFrameId}`,
        method: 'get'
    });
    return response?.data;
};

export const getDisaggregationlist = async (logFrameId: string) => {
    const response = await axiosServices({
        url: `/msurvey/api/logframe/disaggregation-list?logFrameId=${logFrameId}`,
        method: 'get'
    });
    return response?.data;
};

export const baselineDisaggregationCreate = async (data: any) => {
    return axiosServices({
        url: `/msurvey/api/logframe/disaggregation-base-line-create`,
        method: 'post',
        data
    });
};

export const getBaselineDisaggregation = async (logFrameId: string) => {
    const response = await axiosServices({
        url: `/msurvey/api/logframe//baseline-disaggregation?logframeId=${logFrameId}`,
        method: 'get'
    });
    return response?.data;
};

export const logframeYearAdd = async (data: any) => {
    return axiosServices({
        url: `/msurvey/api/logframe/year-add`,
        method: 'post',
        data
    });
};

export const logframeYear = async () => {
    const response = await axiosServices({
        url: `/msurvey/api/logframe/year`,
        method: 'get'
    });
    return response?.data;
};

export const logFrameReport = async (project: string) => {
    const response = await axiosServices({
        url: `/msurvey/api/sacp/log-frame/report?project=${project}`,
        method: 'get'
    });
    return response?.data;
};

export const getProjectNameFromLogframeId = async (logFrameId: string) => {
    const response = await axiosServices({
        url: `/msurvey/api/sacp//project-name?logFrameId=${logFrameId}`,
        method: 'get'
    });
    return response?.data;
};

export const getOutReachData = async (id: string, year: string) => {
    const response = await axiosServices({
        url: `/msurvey/api/sacp/outreach-data?id=${id}&year=${year}`,
        method: 'get'
    });
    return response?.data;
};

export const memberExport = async (district_id: string, division_id: string, firstName: string, union_id: string, upazila_id: string) => {
    try {
        const response = await axiosServices({
            url: `/msurvey/api/sacp/memeber-export?district_id=${district_id}&division_id=${division_id}&firstName=${firstName}&limit=30&page=1&union_id=${union_id}&upazila_id=${upazila_id}`,
            method: 'get',
            responseType: 'blob' // Important for binary data
        });

        const contentDisposition = response.headers['content-disposition'];
        const fileName = contentDisposition ? contentDisposition.split('filename=')[1].split(';')[0] : 'members.xlsx';

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    } catch (error) {
        console.error('Error downloading file:', error);
    }
};

export const getMapData = async (locationType: string) => {
    const response = await axiosServices({
        url: `/msurvey/api/sacp/map-data?locationType=${locationType}`,
        method: 'get'
    });
    return response?.data;
};

export const getBeneficiaryChartData = async (division: string, district: string, upazila: string, union: string) => {
    const response = await axiosServices({
        url: `/msurvey/api/sacp/beneficiary-chart?division=${division}&district=${district}&upazila=${upazila}&union=${union}`,
        method: 'get'
    });
    return response?.data;
};

export const getDemonstrationChartData = async (division: string, district: string, upazila: string, union: string) => {
    const response = await axiosServices({
        url: `/msurvey/api/sacp/demonstration-chart?division=${division}&district=${district}&upazila=${upazila}&union=${union}`,
        method: 'get'
    });
    return response?.data;
};

export const getProjectActivityChartData = async (division: string, district: string, upazila: string, union: string) => {
    const response = await axiosServices({
        url: `/msurvey/api/sacp/project-activity-chart?division=${division}&district=${district}&upazila=${upazila}&union=${union}`,
        method: 'get'
    });
    return response?.data;
};

export const getInfrastructureChartData = async () => {
    const response = await axiosServices({
        url: `/msurvey/api/sacp/infrastructure-chart`,
        method: 'get'
    });
    return response?.data;
};

export const initiateMemberExport = async (division: string, district: string, upazila: string, union: string, search: string) => {
    const response = await axiosServices({
        url: `/msurvey/api/sacp/memeber-export?division=${division}&district=${district}&upazila=${upazila}&union=${union}&firstName=${search}`
    });
    return response?.data;
};

export const initiateGroupExport = async (division: string, district: string, upazila: string, union: string, search: string) => {
    const response = await axiosServices({
        url: `/msurvey/api/sacp/group-export?division_id=${division}&district_id=${district}&upazila_id=${upazila}&union_id=${union}&firstName=${search}`
    });
    return response?.data;
};

export const initiateProjectExport = async (division: string, district: string, upazila: string, search: string) => {
    const response = await axiosServices({
        url: `/msurvey/api/sacp/project-export?division=${division}&district=${district}&upazila=${upazila}&search=${search}`
    });
    return response?.data;
};

// export const fileDownload = async (uuid: string, retries = 10, delay = 5000): Promise<void> => {
//     try {
//         const response = await axiosServices({
//             url: `/msurvey/api/sacp/file-download?uuid=${uuid}`,
//             method: 'get',
//             responseType: 'blob',
//             timeout: 50000
//         });

//         console.log(response, '----------------------------------');
//         const contentDisposition = response.headers['content-disposition'];
//         const fileName = contentDisposition ? contentDisposition.split('filename=')[1].split(';')[0] : 'members.xlsx';

//         const url = window.URL.createObjectURL(new Blob([response.data]));
//         const link = document.createElement('a');
//         link.href = url;
//         link.setAttribute('download', fileName);
//         document.body.appendChild(link);
//         link.click();
//         document.body.removeChild(link);

//         console.log('File download successful.');
//     } catch (error: any) {
//         console.log(error, '-------pp-----');
//         const status = error.response?.status;

//         if ((status === 404 || status === 400) && retries > 0) {
//             console.warn(`File not ready, retrying in ${delay / 1000} seconds...`);
//             setTimeout(() => fileDownload(uuid, retries - 1, delay), delay);
//         } else if (!error.response) {
//             console.error('Network or client error:', error.message);
//         } else {
//             console.error('Error downloading file:', error);
//         }
//     }
// };

export const fileDownload = async (uuid: string, retries = 10, delay = 5000): Promise<void> => {
    try {
        const response = await axiosServices({
            url: `/msurvey/api/sacp/file-download?uuid=${uuid}`,
            method: 'get',
            responseType: 'blob',
            timeout: 300000,
            onDownloadProgress: (progressEvent) => {
                const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                console.log(`Download Progress: ${percentCompleted}%`);
            }
        });

        const contentDisposition = response.headers['content-disposition'];
        const fileName = contentDisposition ? contentDisposition.split('filename=')[1].split(';')[0] : 'downloaded-file.xlsx';

        saveAs(response.data, fileName);

        console.log('File download successful.');
    } catch (error: any) {
        console.error('Error during file download:', error.message || error);

        const status = error.response?.status;
        if ((status === 404 || status === 400) && retries > 0) {
            console.warn(`File not ready, retrying in ${delay / 1000} seconds...`);
            setTimeout(() => fileDownload(uuid, retries - 1, delay), delay);
        } else if (!error.response) {
            console.error('Network or client error:', error.message);
        } else {
            console.error('Error downloading file:', error);
        }
    }
};
