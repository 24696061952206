import { useQuery } from '@tanstack/react-query';
import { notificationKey } from '../../../react_query/query-keys';
import { getNotification } from '../../../services/reactQueryservices';

const useNotification = () => {
    const { data, isFetching, error } = useQuery({
        queryKey: [notificationKey],
        queryFn: () => getNotification()
    })

    return {
        data,
        isFetching,
        error
    }
}

export default useNotification