import React, { useEffect, useState } from 'react';
import { MapContainer, TileLayer, Marker, Popup, Tooltip } from 'react-leaflet';
import L, { LatLngTuple } from 'leaflet';
import useMapdata from 'hooks/consume_api/query/useMapdata';
import { MenuItem, FormControl, Select, InputLabel, SelectChangeEvent } from '@mui/material';
import useDemoOrInfraDataForMap from 'hooks/consume_api/query/useDemoOrInfraDataForMap';

const customIcon = new L.Icon({
    iconUrl: require('leaflet/dist/images/marker-icon.png').default,
    iconSize: [15, 31],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowUrl: require('leaflet/dist/images/marker-shadow.png').default,
    shadowSize: [41, 41]
});

type MapMarker = {
    position: [number, number];
    tooltipText: string;
};

type MapMarkersResponse = MapMarker[];

const Map: React.FC = () => {
    const position = [51.505, -0.09];
    const markers: { position: LatLngTuple; tooltipText: string }[] = [
        { position: [23.685, 90.3563], tooltipText: 'Marker 1' },
        { position: [24.685, 91.3563], tooltipText: 'Marker 2' },
        { position: [22.685, 89.3563], tooltipText: 'Marker 3' }
    ];
    const [mapData, setMapData] = useState<MapMarkersResponse>([]);
    const [selectedOption, setSelectedOption] = useState<string>('division');
    const [type, setType] = useState<string>("");

    const handleChange = (event: SelectChangeEvent): void => {
        setSelectedOption(event.target.value);
    };

    const handleType = (event: SelectChangeEvent) => {
        setType(event.target.value);
    }

    const { data } = useMapdata(selectedOption);
    const { data: demoOrInfra } = useDemoOrInfraDataForMap(type);

    useEffect(() => {
        if (demoOrInfra) {
            const a = demoOrInfra.data.map((marker: any) => ({
                ...marker,
                position: [parseFloat(marker.possition[0]), parseFloat(marker.possition[1])]
            }));
            setMapData(a);
        }
    }, [demoOrInfra])

    useEffect(() => {
        if (data?.data) {
            const parsedData = data.data.map((marker: any) => ({
                ...marker,
                position: [parseFloat(marker.position[0]), parseFloat(marker.position[1])]
            }));
            setMapData(parsedData);
        }
    }, [data]);
    return (
        <>
            <FormControl variant="outlined" style={{ marginTop: '20px', marginBottom: '20px', width: '200px' }}>
                <InputLabel id="dropdown-label">Select Location</InputLabel>
                <Select labelId="dropdown-label" id="dropdown" value={selectedOption} onChange={handleChange} label="Select Category">
                    <MenuItem value="division">Division</MenuItem>
                    <MenuItem value="district">District</MenuItem>
                    <MenuItem value="upazila">Upazila</MenuItem>
                </Select>
            </FormControl>

            <FormControl variant="outlined" style={{ marginTop: '20px', marginBottom: '20px', marginLeft: '20px', width: '300px' }}>
                <InputLabel id="dropdown-label">Select Demonstration or Infrastructure</InputLabel>
                <Select labelId="dropdown-label" id="dropdown" label="Select Category" onChange={handleType}>
                    <MenuItem value="demo">Demonstration</MenuItem>
                    <MenuItem value="infra">Infrastructure</MenuItem>
                </Select>
            </FormControl>

            <MapContainer center={[23.685, 90.3563]} zoom={7} scrollWheelZoom={false} style={{ height: '100vh', width: '100%' }}>
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                {mapData.map((marker, index) => (
                    <Marker key={index} position={marker.position} icon={customIcon}>
                        <Tooltip permanent>{marker.tooltipText ? marker.tooltipText : "1"}</Tooltip>
                    </Marker>
                ))}
            </MapContainer>
        </>
    );
};

export default Map;
