import React, { useEffect, useState } from 'react';
import MainCard from 'ui-component/cards/MainCard';
import {
    Autocomplete,
    Button,
    CardContent,
    Grid,
    Paper,
    Table,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
    TableBody,
    Stack,
    IconButton,
    Tooltip,
    Pagination, InputLabel, Select, MenuItem, FormControl, SelectChangeEvent
} from '@mui/material';
import { gridSpacing } from 'store/constant';
import { GroupType } from 'types/sacp';
import { useNavigate, useParams } from 'react-router-dom';
import LibraryAddOutlinedIcon from '@mui/icons-material/LibraryAddOutlined';
import { useTheme } from '@mui/material/styles';
import ConfirmDeleteModal from 'components/common/ConfirmDeleteModal';
import { columnListNameGroup, dataColumns, listDefaultData } from '../Constant';
import ServerPagingTable from 'components/Table/ServerPagingTable';
import { getGroupList, deleteInstance } from 'services/sacp';
import Loader from 'ui-component/Loader';
import useGetLocationList from 'hooks/consume_api/query/useGetLocationList';
import BorderColorTwoToneIcon from '@mui/icons-material/BorderColorTwoTone';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import useAuth from 'hooks/useAuth';
import DeleteIcon from '@material-ui/icons/Delete';
import useGroupDelete from 'hooks/consume_api/mutation/useGroupDelete';
import GroupDeleteModal from './GroupDeleteModal';
import useDebounce from 'hooks/useDebounce';
import { fileDownload, initiateGroupExport } from 'services/reactQueryservices';

interface FieldOption {
    id: number;
    field_name: string;
    field_parent_id: number;
    field_type_id: number;
    geocode: string;
    geojson: object;
    uploaded_file_path: string;
    created_by: number;
    updated_by: number;
    created_date: string;
    updated_date: string;
    longitude: number | null;
    latitude: number | null;
    field_name_bn: string | null;
}

const GroupRegister = () => {
    const theme = useTheme();
    const { id: projectId } = useParams();
    const navigate = useNavigate();

    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [selectedData, setSelectedData] = useState<GroupType | null>(null);
    const [deletePermission, setDeletePermission] = useState<boolean>(false);
    const [data, setData] = useState<any>();
    const [isDelete, setIsDelete] = useState<boolean>(false);
    const [row, setRow] = useState<number>(30);
    const [search, setSearch] = useState<string>('');

    const [openModal, setOpenModal] = useState<boolean>(false);
    const [groupId, setGroupId] = useState<number>(0);

    const handleOpenModal = () => setOpenModal(true);
    const handleCloseModal = () => setOpenModal(false);
    const enableDelete = () => setIsDelete(!isDelete);
    const debouncedSearchValue = useDebounce(search, 500);


    const { mutate, isSuccess } = useGroupDelete();

    const [page, setPage] = useState<number>(1);

    const { user } = useAuth();

    const [isFetching, setIsFetching] = useState<boolean>(false);

    const formId = process.env.REACT_APP_GROUP_FORM_ID;
    const xformId = process.env.REACT_APP_GROUP_XFORM_ID;

    const submissionUrl = `/project/${projectId}/form/${formId}/${xformId}/new-submission`;
    const editUrl = `/project/${projectId}/form/${formId}/${xformId}/edit-submission`;
    const profileUrl = `/project/${projectId}/sacp/group`;

    const [location, setLocation] = useState<string>('-1');
    const [divisionId, setDivisionId] = useState<string>('');
    const [districtId, setDistrictId] = useState<string>('');
    const [upazilaId, setUpazilaId] = useState<string>('');
    const [unionId, setUnionId] = useState<string>('');

    const { data: divisionData } = useGetLocationList(location);
    const { data: distirctData } = useGetLocationList(divisionId);
    const { data: upazilaData } = useGetLocationList(districtId);
    const { data: unionData } = useGetLocationList(upazilaId);

    const fetchData = async (page: number, limit: number, division_id: string, district_id: string, upazila_id: string, union_id: string, search: string) => {
        setIsFetching(true);
        const groupData = await getGroupList({ page, limit, division_id, district_id, upazila_id, union_id, firstName: search });
        setIsFetching(false);
        setData(groupData);
    };

    const handleDeleteConfirm = async () => {
        await deleteInstance({ dataId: selectedData?.id });
    };

    const handleAddClick = () => {
        navigate(submissionUrl);
    };

    const handleDelete = (id: number) => {
        setGroupId(id);
        handleOpenModal();
    };

    const handleChangePage = (_: React.ChangeEvent<unknown>, newPage: number) => {
        setPage(newPage);
    };

    const handleRowChange = (event: SelectChangeEvent) => {
        setRow(Number(event.target.value));
    };

    useEffect(() => {
        fetchData(page, row, divisionId, districtId, upazilaId, unionId, debouncedSearchValue.toLowerCase());
    }, [page, divisionId, districtId, upazilaId, unionId, isDelete, row, debouncedSearchValue]);

    useEffect(() => {
        if (user) {
            user.roles.map((item, index) => {
                if (item.name === 'FAO_ADMIN') {
                    setDeletePermission(true);
                }
            });
        }
    }, []);

    useEffect(() => {
        if (isSuccess) {
            setIsDelete(!isDelete);
        }
    }, [isSuccess]);

    const handleExport = async () => {
        const fileUuid = await initiateGroupExport(divisionId, districtId, upazilaId, unionId, search);
        await fileDownload(fileUuid.data);
    }

    return (
        <MainCard
            content={false}
            contentSX={{ padding: 0 }}
            sx-={{ padding: 0 }}
            title={
                <Grid container alignItems="center" justifyContent="space-between" spacing={gridSpacing}>
                    <Grid item>
                        <Typography variant="h3">Group List</Typography>
                    </Grid>
                    <Grid item>
                        <Button sx={{ ml: -1 }} color="secondary" variant="contained" onClick={() => handleAddClick()}>
                            <LibraryAddOutlinedIcon fontSize="small" sx={{ mr: 0.75 }} />
                            Add New Group
                        </Button>
                        <Button
                            sx={{ ml: 1 }}
                            color="secondary"
                            variant="contained"
                            onClick={handleExport}>
                            Export
                        </Button>
                    </Grid>
                </Grid>
            }
        >
            {isFetching ? (
                <Loader />
            ) : data?.data?.data.length !== 0 ? (
                <CardContent>
                    <Grid container spacing={gridSpacing} marginBottom="15px">
                        <Grid item lg={3} sm={6}>
                            <Autocomplete
                                options={divisionData?.data as FieldOption[]}
                                getOptionLabel={(option) => option.field_name}
                                value={divisionData?.data.find((item: any) => item.id.toString() === divisionId) || null}
                                onChange={(event, value) => {
                                    if (value) {
                                        setDivisionId(value?.id.toString());
                                        setDistrictId('');
                                        setUpazilaId('');
                                        setUnionId('');
                                    }
                                }}
                                renderInput={(params) => <TextField {...params} label="Select division" variant="outlined" />}
                            />
                        </Grid>
                        <Grid item lg={3} sm={6}>
                            <Autocomplete
                                options={(distirctData?.data as FieldOption[]) ?? []}
                                getOptionLabel={(option) => option.field_name}
                                value={distirctData?.data.find((item: any) => item.id.toString() === districtId) || null}
                                onChange={(event, value) => {
                                    if (value) {
                                        setDistrictId(value?.id.toString());
                                        setUpazilaId('');
                                        setUnionId('');
                                    }
                                }}
                                renderInput={(params) => <TextField {...params} label="Select district" variant="outlined" />}
                            />
                        </Grid>
                        <Grid item lg={3} sm={6}>
                            <Autocomplete
                                options={(upazilaData?.data as FieldOption[]) ?? []}
                                getOptionLabel={(option) => option.field_name}
                                value={upazilaData?.data.find((item: any) => item.id.toString() === upazilaId) || null}
                                onChange={(event, value) => {
                                    if (value) {
                                        setUpazilaId(value?.id.toString());
                                        setUnionId('');
                                    }
                                }}
                                renderInput={(params) => <TextField {...params} label="Select upazila" variant="outlined" />}
                            />
                        </Grid>
                        <Grid item lg={3} sm={6}>
                            <Autocomplete
                                options={(unionData?.data as FieldOption[]) ?? []}
                                getOptionLabel={(option) => option.field_name}
                                value={unionData?.data.find((item: any) => item.id.toString() === unionId) || null}
                                onChange={(event, value) => {
                                    if (value) setUnionId(value?.id.toString());
                                }}
                                renderInput={(params) => <TextField {...params} label="Select union" variant="outlined" />}
                            />
                        </Grid>
                    </Grid>
                    <Grid display="flex" justifyContent="end">
                        <TextField onChange={(e) => setSearch(e.target.value)} value={search} id="outlined-basic" label="search" variant="outlined" />
                    </Grid>
                    <TableContainer component={Paper}>
                        <Table sx={{ width: '100%' }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left">#</TableCell>
                                    <TableCell align="left">group Id</TableCell>
                                    <TableCell align="left">Group Name</TableCell>
                                    <TableCell align="left">Group Type</TableCell>
                                    <TableCell align="left">Address</TableCell>
                                    <TableCell align="left">Formation Date</TableCell>
                                    <TableCell align="left">Member count</TableCell>
                                    <TableCell align="center">Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {data?.data?.data &&
                                    data.data.data.map((item: any, index: number) => (
                                        <TableRow key={index}>
                                            <TableCell align="left">{(page - 1) * row + index + 1}</TableCell>
                                            <TableCell align="left">{item.groupCode}</TableCell>
                                            <TableCell align="left">{item.name}</TableCell>
                                            <TableCell align="left">{item.groupType}</TableCell>
                                            <TableCell align="left">{item.address}</TableCell>
                                            <TableCell align="left">{item.formationDate}</TableCell>
                                            <TableCell align="left">{item.memberCount}</TableCell>
                                            <TableCell>
                                                <Stack direction="row">
                                                    <Tooltip placement="top" title="Edit">
                                                        <IconButton
                                                            color="primary"
                                                            sx={{
                                                                color: theme.palette.warning.dark,
                                                                borderColor: theme.palette.warning.main,
                                                                '&:hover ': { background: theme.palette.warning.light }
                                                            }}
                                                            size="large"
                                                            onClick={() => {
                                                                navigate(`${editUrl}/${item.id}`);
                                                            }}
                                                        >
                                                            <BorderColorTwoToneIcon sx={{ fontSize: '1.3rem' }} />
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip placement="top" title="Profile">
                                                        <IconButton
                                                            color="primary"
                                                            sx={{
                                                                color: theme.palette.success.dark,
                                                                borderColor: theme.palette.success.main,
                                                                '&:hover ': { background: theme.palette.success.light }
                                                            }}
                                                            size="large"
                                                            onClick={() => {
                                                                navigate(`${profileUrl}/${item.id}`);
                                                            }}
                                                        >
                                                            <PersonOutlineIcon sx={{ fontSize: '1.3rem' }} />
                                                        </IconButton>
                                                    </Tooltip>
                                                    {deletePermission && (
                                                        <Tooltip placement="top" title="Delete">
                                                            <IconButton
                                                                sx={{
                                                                    color: 'red',
                                                                    borderColor: theme.palette.success.main,
                                                                    '&:hover ': { background: theme.palette.success.light }
                                                                }}
                                                                size="large"
                                                                onClick={() => {
                                                                    handleDelete(item.id);
                                                                }}
                                                            >
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                    )}
                                                </Stack>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                        <Grid display="flex" justifyContent="space-between" marginTop="10px">
                            <Typography>Total: {data?.data?.totalCount}</Typography>
                            <FormControl sx={{ width: '70px' }}>
                                <InputLabel id="demo-simple-select-label">Row</InputLabel>
                                <Select onChange={handleRowChange} value={row.toString()}>
                                    <MenuItem value={30}>30</MenuItem>
                                    <MenuItem value={50}>50</MenuItem>
                                    <MenuItem value={100}>100</MenuItem>
                                </Select>
                            </FormControl>
                            <Pagination
                                count={Math.ceil(data?.data?.totalCount / row)}
                                color="primary"
                                page={page}
                                onChange={handleChangePage}
                            />
                        </Grid>
                    </TableContainer>
                    {openModal && <GroupDeleteModal
                        open={openModal}
                        handleClose={handleCloseModal}
                        mutate={mutate}
                        id={groupId}

                    />}
                    {/* <ServerPagingTable
                        data={data?.data}
                        columns={dataColumns(
                            theme,
                            columnListNameGroup,
                            editUrl,
                            profileUrl,
                            navigate,
                            setSelectedData,
                            setIsDeleteModalOpen
                        )}
                        fetchData={fetchData}
                        showSL
                    /> */}
                </CardContent>
            ) : (
                <CardContent>
                    <Typography variant="body1">No Group data found</Typography>
                </CardContent>
            )}

            {isDeleteModalOpen && (
                <ConfirmDeleteModal
                    open={isDeleteModalOpen}
                    setOpen={setIsDeleteModalOpen}
                    subTitle={`Delete Group '${selectedData?.name}'?`}
                    handleConfirm={handleDeleteConfirm}
                />
            )}
        </MainCard>
    );
};

export default GroupRegister;
