import { useQuery } from '@tanstack/react-query';
import { getBeneficiaryChartData } from 'services/reactQueryservices';
import { beneficiaryChartDataKey } from 'react_query/query-keys';

const useBeneficiaryChartData = (division: string, district: string, upazila: string, union: string) => {
    const { data, isFetching, error } = useQuery({
        queryKey: [beneficiaryChartDataKey, division, district, upazila, union],
        queryFn: () => getBeneficiaryChartData(division, district, upazila, union)
    });

    return {
        data,
        isFetching,
        error
    };
}

export default useBeneficiaryChartData;