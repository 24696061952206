import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import CheckIcon from '@mui/icons-material/Check';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { Button, IconButton, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import useValidationListBySubActivity from 'hooks/consume_api/query/useValidationListBySubActivity';
import MUIDataTable, { CustomHeadLabelRenderOptions, MUIDataTableMeta } from 'mui-datatables';
import { useTheme } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import VisibilityIcon from '@mui/icons-material/Visibility';
import useValidationSingleSubmission from 'hooks/consume_api/mutation/useValidationSingleSubmission';
import useProjectDetails from 'hooks/consume_api/query/useProjectDetails';
import { useNavigate, useParams } from 'react-router-dom';
import useValidationRejection from 'hooks/consume_api/mutation/useValidationRejection';

interface IValidationListProps {
    handleClose: () => void;
    open: boolean;
    subActivityType: string;
}

const ValidationListBySubActivity: React.FC<IValidationListProps> = ({ open, handleClose, subActivityType }) => {
    const theme = useTheme();
    const navigate = useNavigate();
    const { id: projectId } = useParams();

    const { data, isFetching } = useValidationListBySubActivity(subActivityType);
    const { mutate: singleSubmitionMutate } = useValidationSingleSubmission();
    const { mutate: validationRejection } = useValidationRejection();
    const [instanceId, setInstanceId] = React.useState<string>('');

    const { data: projectData } = useProjectDetails(instanceId);

    const handleViewProject = (instanceId: number) => {
        setInstanceId(instanceId.toString());
    };

    React.useEffect(() => {
        if (instanceId) {
            navigate(
                `/project/${projectId}/sacp/project-activity/profile?subactivity=${projectData?.data.sub_activity}&subactivityId=${projectData?.data.sub_activity_type}&date=${projectData?.data.implementation_date}&location=${projectData?.data.location}&instance=${projectData?.data.id}&implementedby=${projectData?.data.implemented_by}`
            );
        }
    }, [instanceId]);

    const Transition = React.forwardRef(function Transition(
        props: TransitionProps & {
            children: React.ReactElement<any, any>;
        },
        ref: React.Ref<unknown>
    ) {
        return <Slide direction="up" ref={ref} {...props} />;
    });

    const columns = [
        {
            name: 'Row No',
            label: '#',
            options: {
                filter: false,
                sort: false,
                customBodyRender: (value: any, tableMeta: any) => {
                    return tableMeta.rowIndex + 1;
                }
            }
        },
        { name: 'sub_activity_name', label: 'Sub activity name' },
        {
            name: 'id',
            label: 'Action',
            options: {
                filter: true,
                sort: false,
                customBodyRender: (id: number, tableMeta: MUIDataTableMeta) => {
                    return (
                        <>
                            <Stack direction="row" justifyContent="center" alignItems="center">
                                <Tooltip placement="top" title="Approve">
                                    <IconButton
                                        onClick={() => {
                                            singleSubmitionMutate({ instanceId: id, isValidate: true });
                                            handleClose();
                                        }}
                                        color="success"
                                        sx={{
                                            color: theme.palette.success.dark,
                                            borderColor: theme.palette.success.main,
                                            '&:hover ': { background: theme.palette.success.light }
                                        }}
                                        size="large"
                                    >
                                        <CheckIcon sx={{ fontSize: '1.3rem' }} />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip placement="top" title="view">
                                    <IconButton
                                        onClick={() => {
                                            handleViewProject(id);
                                        }}
                                        color="success"
                                        sx={{
                                            color: theme.palette.info.dark,
                                            borderColor: theme.palette.info.main,
                                            '&:hover ': { background: theme.palette.info.light }
                                        }}
                                        size="large"
                                    >
                                        <VisibilityIcon sx={{ fontSize: '1.3rem' }} />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip placement="top" title="Reject">
                                    <IconButton
                                        onClick={() => {
                                            validationRejection(id.toString());
                                        }}
                                        color="success"
                                        sx={{
                                            color: theme.palette.error.dark,
                                            borderColor: theme.palette.error.main,
                                            '&:hover ': { background: theme.palette.error.light }
                                        }}
                                        size="large"
                                    >
                                        <CloseIcon sx={{ fontSize: '1.3rem' }} />
                                    </IconButton>
                                </Tooltip>
                            </Stack>
                        </>
                    );
                },
                customHeadLabelRender: (options: CustomHeadLabelRenderOptions) => (
                    <Stack direction="row" justifyContent="center" alignItems="center">
                        {options.label}
                    </Stack>
                )
            }
        }
    ];

    const options = {
        filterType: 'checkbox' as const,
        selectableRows: 'none' as const,
        elevation: 0,
        searchAlwaysOpen: false,
        searchPlaceholder: 'Search',
        download: false,
        search: false,
        print: false,
        filter: false,
        viewColumns: false
    };

    return (
        <React.Fragment>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
                maxWidth="xl"
            >
                <DialogContent>
                    {data?.data && <MUIDataTable title="" data={data?.data} columns={columns} options={options} />}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Close</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
};

export default ValidationListBySubActivity;
