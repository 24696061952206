import React from 'react'
import Chart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';
import useBeneficiaryChartData from 'hooks/consume_api/query/useBeneficiaryChartData';
import { Grid, Typography } from '@mui/material';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

interface props {
    division: string,
    district: string,
    upazila: string,
    union: string
}

const Benificiary: React.FC<props> = ({ division, district, upazila, union }) => {
    const { data } = useBeneficiaryChartData(division, district, upazila, union);
    return (
        <><Typography sx={{ marginTop: "10px", marginBottom: "10px" }} variant='h3'>Beneficiary</Typography>
            <Grid container >
                {data && (
                    <>

                        <Grid item xs={12}>
                            <TableContainer component={Paper} sx={{ border: '1px solid black', borderRadius: '1px' }}>
                                <Table sx={{ borderCollapse: 'collapse' }}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell sx={{ borderBottom: '1px solid black', borderRight: '1px solid black' }}>Total Beneficiary</TableCell>
                                            <TableCell sx={{ borderBottom: '1px solid black', borderRight: '1px solid black' }}>Male beneficiary</TableCell>
                                            <TableCell sx={{ borderBottom: '1px solid black', borderRight: '1px solid black' }}>Female beneficiary</TableCell>
                                            <TableCell sx={{ borderBottom: '1px solid black' }}>Youth Beneficiary</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {/* Example Rows */}
                                        <TableRow>
                                            <TableCell sx={{ borderRight: '1px solid black' }}>{data?.data?.male?.series[0]}</TableCell>
                                            <TableCell sx={{ borderRight: '1px solid black' }}>{data?.data?.male?.series[1]}</TableCell>
                                            <TableCell sx={{ borderRight: '1px solid black' }}>{data?.data?.female?.series[1]}</TableCell>
                                            <TableCell>{data?.data?.female?.series[1]}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>

                        </Grid>
                        <Grid sx={{ marginTop: "20px" }} item xs={4}>
                            <Chart
                                options={data?.data?.male?.pieOption as ApexOptions}
                                series={data?.data?.male?.series}
                                type="pie"
                                height={250}
                            />
                        </Grid>
                        <Grid sx={{ marginTop: "20px" }} item xs={4}>
                            <Chart
                                options={data?.data?.female?.pieOption as ApexOptions}
                                series={data?.data?.female?.series}
                                type="pie"
                                height={250}
                            />
                        </Grid>
                        <Grid sx={{ marginTop: "20px" }} item xs={4}>
                            <Chart
                                options={data?.data?.youth?.pieOption as ApexOptions}
                                series={data?.data?.youth?.series}
                                type="pie"
                                height={250}
                            />
                        </Grid>
                        <Grid sx={{ marginTop: "20px" }} item xs={12}>
                            <TableContainer component={Paper} sx={{ border: '1px solid black', borderRadius: '1px' }}>
                                <Table sx={{ borderCollapse: 'collapse' }}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell sx={{ borderBottom: '1px solid black', borderRight: '1px solid black' }}>Particular</TableCell>
                                            <TableCell sx={{ borderBottom: '1px solid black', borderRight: '1px solid black' }}>Male</TableCell>
                                            <TableCell sx={{ borderBottom: '1px solid black', borderRight: '1px solid black' }}>Female</TableCell>
                                            <TableCell sx={{ borderBottom: '1px solid black' }}>Total</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {/* Example Rows */}
                                        <TableRow>
                                            <TableCell sx={{ borderBottom: '1px solid black', borderRight: '1px solid black' }}>Beneficiaries</TableCell>
                                            <TableCell sx={{ borderBottom: '1px solid black', borderRight: '1px solid black' }}>{data?.data?.basicReport.male_count}</TableCell>
                                            <TableCell sx={{ borderBottom: '1px solid black', borderRight: '1px solid black' }}>{data?.data?.basicReport.female_count}</TableCell>
                                            <TableCell sx={{ borderBottom: '1px solid black' }}>{Number(data?.data?.basicReport.male_count) + Number(data?.data?.basicReport.female_count)}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell sx={{ borderBottom: '1px solid black', borderRight: '1px solid black' }}>Youth beneficiaries</TableCell>
                                            <TableCell sx={{ borderBottom: '1px solid black', borderRight: '1px solid black' }}>{data?.data?.basicReport.male_youth}</TableCell>
                                            <TableCell sx={{ borderBottom: '1px solid black', borderRight: '1px solid black' }}>{data?.data?.basicReport.female_youth}</TableCell>
                                            <TableCell sx={{ borderBottom: '1px solid black' }}>{Number(data?.data?.basicReport.male_youth) + Number(data?.data?.basicReport.female_youth)}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell sx={{ borderBottom: '1px solid black', borderRight: '1px solid black' }}>Ethnic beneficiaries</TableCell>
                                            <TableCell sx={{ borderBottom: '1px solid black', borderRight: '1px solid black' }}>{data?.data?.basicReport.male_ethnic}</TableCell>
                                            <TableCell sx={{ borderBottom: '1px solid black', borderRight: '1px solid black' }}>{data?.data?.basicReport.female_ethnic}</TableCell>
                                            <TableCell sx={{ borderBottom: '1px solid black' }}>{Number(data?.data?.basicReport.male_ethnic) + Number(data?.data?.basicReport.female_ethnic)}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell sx={{ borderBottom: '1px solid black', borderRight: '1px solid black' }}>Household head</TableCell>
                                            <TableCell sx={{ borderBottom: '1px solid black', borderRight: '1px solid black' }}>{data?.data?.basicReport.male_house_hold}</TableCell>
                                            <TableCell sx={{ borderBottom: '1px solid black', borderRight: '1px solid black' }}>{data?.data?.basicReport.female_house_hold}</TableCell>
                                            <TableCell sx={{ borderBottom: '1px solid black' }}>{Number(data?.data?.basicReport.male_house_hold) + Number(data?.data?.basicReport.female_house_hold)}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell sx={{ borderRight: '1px solid black' }}>Household Member</TableCell>
                                            <TableCell sx={{ borderRight: '1px solid black' }}>{data?.data?.basicReport.house_hold_male_member}</TableCell>
                                            <TableCell sx={{ borderRight: '1px solid black' }}>{data?.data?.basicReport.house_hold_female_member}</TableCell>
                                            <TableCell>{Number(data?.data?.basicReport.house_hold_male_member) + Number(data?.data?.basicReport.house_hold_female_member)}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>

                        </Grid>

                        <Grid marginTop="10px" item xs={12}>
                            <TableContainer component={Paper} sx={{ border: '1px solid black', borderRadius: '1px' }}>
                                <Table sx={{ borderCollapse: 'collapse' }}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell sx={{ borderBottom: '1px solid black', borderRight: '1px solid black' }}>Land information</TableCell>
                                            <TableCell sx={{ borderBottom: '1px solid black', borderRight: '1px solid black' }}>0-5 decimal</TableCell>
                                            <TableCell sx={{ borderBottom: '1px solid black', borderRight: '1px solid black' }}>6-249 decimal</TableCell>
                                            <TableCell sx={{ borderBottom: '1px solid black' }}>More than 249 decimal</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell sx={{ borderBottom: '1px solid black', borderRight: '1px solid black' }}>Beneficiaries</TableCell>
                                            <TableCell sx={{ borderBottom: '1px solid black', borderRight: '1px solid black' }}>{data?.data?.landinfo.total_one}</TableCell>
                                            <TableCell sx={{ borderBottom: '1px solid black', borderRight: '1px solid black' }}>{data?.data?.landinfo.total_two}</TableCell>
                                            <TableCell sx={{ borderBottom: '1px solid black' }}>{data?.data?.landinfo.total_three}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell sx={{ borderBottom: '1px solid black', borderRight: '1px solid black' }}>Youth Beneficiaries</TableCell>
                                            <TableCell sx={{ borderBottom: '1px solid black', borderRight: '1px solid black' }}>{data?.data?.landinfo.youth_one}</TableCell>
                                            <TableCell sx={{ borderBottom: '1px solid black', borderRight: '1px solid black' }}>{data?.data?.landinfo.youth_two}</TableCell>
                                            <TableCell sx={{ borderBottom: '1px solid black' }}>{data?.data?.landinfo.youth_three}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell sx={{ borderRight: '1px solid black' }}>Ethnic Beneficiaries</TableCell>
                                            <TableCell sx={{ borderRight: '1px solid black' }}>{data?.data?.landinfo.ethnicity_one}</TableCell>
                                            <TableCell sx={{ borderRight: '1px solid black' }}>{data?.data?.landinfo.ethnicity_two}</TableCell>
                                            <TableCell >{data?.data?.landinfo.ethnicity_three}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>

                        </Grid>
                    </>
                )}
            </Grid>
        </>
    )
}

export default Benificiary