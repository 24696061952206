import { useQuery } from '@tanstack/react-query';
import { getDemonstrationChartData } from 'services/reactQueryservices';
import { demonstrattionChartDatakey } from 'react_query/query-keys';

const useDemonstrationChartData = (division: string, district: string, upazila: string, union: string) => {
    const { data, isFetching, error } = useQuery({
        queryKey: [demonstrattionChartDatakey, division, district, upazila, union],
        queryFn: () => getDemonstrationChartData(division, district, upazila, union)
    });

    return {
        data,
        isFetching,
        error
    };
}

export default useDemonstrationChartData